import React, { useEffect, useState, useContext, Suspense, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, createTheme, useMediaQuery, Alert, Typography } from '@mui/material';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { InputSelect } from '@aldridge/aldg-data-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { allPartsExistWithLength, useFunctionCall } from '@aldridge/aldg-helpers';
import dayjs from 'dayjs';
import { faClone } from '@fortawesome/pro-regular-svg-icons';
import getMostRecentMonth from '../utils/getMostRecentMonth';
import { StatusContext } from '../providers/StatusProvider';
import UserAssignmentJob from '../Components/Jobs/UserAssignment/UserAssignmentJob';
import CashFlow from '../Components/Jobs/CashFlow/CashFlow';
import getPreferences from '../Components/Jobs/Dashboard/getPreferences';
import MenuCardData from '../Components/Jobs/Dashboard/MenuCardData';
import ActionItems from '../Components/Jobs/ActionItems/ActionItems';
import Agenda from '../Components/Jobs/Agenda/Agenda';
import QuickActions from '../Components/Jobs/QuickActions/QuickActions';
// import Quantity from '../Components/Jobs/Quantity/Quantity';
import Attachments from '../Components/Jobs/Attachments/Attachments';
import MonthEndOpts from '../Components/UIComponents/MonthEndOpts';
import Fallback from '../Components/UIComponents/Fallback';
import RevisedBudget from '../Components/Jobs/ChangeOrder/RevisedBudget';
import ChangeOrderForm from '../Components/Jobs/ChangeOrder/ChangeOrderForm';
import ErrorBoundary from '../_GlobalComponents/ErrorBoundary';
import ClickstreamControlBar from '../Components/Jobs/Agenda/ClickstreamControlBar';
import NewMonthModal from '../Components/Jobs/Dashboard/NewMonthModal';
import { getPrevMonth } from '../utils/monthEndOptions';
import { _Clickstream, _CurrentJob, _Defaults, _MonthEndDate, _Preferences, _SecurityLevel, _StatusMonth } from '../_Recoil/atoms';
import MiniSidebar from '../Components/Jobs/Dashboard/MiniSidebar';
import Dashboard from '../Components/Jobs/Dashboard/Dashboard';
import Forecasting from '../Components/Jobs/Forecasting/Forecasting';
import JobDetails from '../Components/Jobs/JobInformation/JobDetails';
// eslint-disable-next-line no-unused-vars
import CostCodes from '../Components/Jobs/CostCodes/CostCodes';
import { config, firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';
import UnderOverBilling from '../Components/Jobs/Underbilling/UnderOverBilling';
import ChangeOrder from '../Components/Jobs/ChangeOrder/ChangeOrder';
import FinancialWorkbook from '../Components/Jobs/FinancialWorkbook/FinancialWorkbook';
import NotesList from '../Components/Jobs/Notes/NotesList';
import getLastIndex from '../utils/getLastIndex';
import ProjectStatus from '../Components/Jobs/ProjectStatus/ProjectStatus';
import existsWithLength from '../utils/existsWithLength';
import { EditorUp, Unauthorized } from '../Components/Jobs/UserAssignment/checkSecurity';
import getSecurityLevel from '../Components/Jobs/UserAssignment/getSecurityLevel';
import UnauthorizedJob from './UnauthorizedJob';
import DisplayAttachments from '../Components/Jobs/Attachments/DisplayAttachments';
import JobSwitcher from '../Components/UIComponents/JobSwitcher';
import ContractAbstractList from '../Components/Jobs/ContractAbstract/ContractAbstractList';
import ContractAbstract from '../Components/Jobs/ContractAbstract/ContractAbstract';
import MonthRollback from '../Components/Jobs/MonthRollback/MonthRollback';

const DisplayDataPull = ({ projectId, recordId }) => {
  const { data, loading, error, functionCall } = useFunctionCall('copyFromProd', {}, 540000);

  useEffect(() => {
    if (!loading && existsWithLength(data)) {
      toast.success(data, { autoClose: 10000 });
    }
    if (!loading && existsWithLength(error)) {
      toast.error(error.message);
    }
  }, [data, loading, error]);

  return projectId.indexOf('-dev') > -1 || projectId.indexOf('-training') > -1 ? (
    <ToolTipIconButton
      icon={faClone}
      onClick={() => {
        functionCall({ initialPath: `ENT-Jobs/${recordId}` });
        toast.info('This process may take a few minutes. Please do not leave this page, or do anything until you get a secondary message.', {
          autoClose: 100000
        });
      }}
      loading={loading}
      tooltipText='Pull Production Data'
    />
  ) : null;
};
DisplayDataPull.propTypes = {
  projectId: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired
};

const JobInformation = (props) => {
  const { history, match } = props;
  const user = useContext(UserContext);
  const [page, setPage] = useState(<Fallback />);
  const [DisableMonthEnd, setDisableMonthEnd] = useState(false);
  const [monthEndDate, setMonthEndDate] = useRecoilState(_MonthEndDate);
  const [title, setTitle] = useState('Dashboard');
  // const [subjobs, setSubjobs] = useState([]);
  const [prevMemorialized, setPrevMemorialized] = useState(true);
  const [memorialized, setMemorialized] = useState(false);
  const [mostRecentMonth, setMostRecentMonth] = useState(undefined);
  const [CurrentJob, setCurrentJob] = useRecoilState(_CurrentJob);
  const [SecurityLevel, setSecurityLevel] = useRecoilState(_SecurityLevel);
  const [Defaults, setDefaults] = useRecoilState(_Defaults);
  const [firstMonth, setFirstMonth] = useState(false);
  const CurrStatusMonth = useRecoilValue(_StatusMonth);
  const NextFromStatusMonth = dayjs(CurrStatusMonth).add(1, 'M').endOf('month').format('YYYY-MM-DD');
  const StatusMonth = useRecoilValue(_StatusMonth);
  const [IsStatusMonth, setIsStatusMonth] = useState(false);

  const setPreferences = useSetRecoilState(_Preferences);
  const Clickstream = useRecoilValue(_Clickstream);
  const { subjobs } = useContext(StatusContext);
  const mounted = useRef(null);
  const matches = useMediaQuery(createTheme().breakpoints.down('lg'));
  const inClickstream = Clickstream.currentStep >= 0;
  const inAgenda = Clickstream.inAgenda;

  useEffect(() => {
    setIsStatusMonth(StatusMonth === monthEndDate);
  }, [StatusMonth, monthEndDate]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedTransaction = { ...CurrentJob };
      changedTransaction[id] = value;
      console.log(id, value, typeof changedTransaction.ToolingAllocation);
      if (typeof displayFromTypeahead !== 'undefined') {
        changedTransaction[`${id}Display`] = displayFromTypeahead;
      }
      if (changedTransaction.CreatedBy === '' || typeof changedTransaction.changedTransaction === 'undefined') {
        changedTransaction.CreatedBy = user.email;
        changedTransaction.CreatedDate = new Date().toJSON();
      }
      changedTransaction.ModifiedBy = user.email;
      changedTransaction.ModifiedDate = new Date().toJSON();
      console.log('here', changedTransaction, `[${changedTransaction.EcmsRevisedCompletionDate}]`);
      if (changedTransaction.EcmsRevisedCompletionDate !== '') {
        changedTransaction.EcmsRevisedCompletionDate?.forEach((date) => {
          if (date.EcmsRevisedCompletionDate === '') {
            date.EcmsRevisedCompletionDate = dayjs().format('YYYY-MM-DD');
          }
        });
      }
      setCurrentJob(changedTransaction);
    } catch (err) {
      console.log('error', err);
      toast.error(err.message);
    }
  };

  const invalidJobDetected = () => {
    toast.error('Invalid job. Please click on a job from the list page.', { autoClose: 10000 });
    history.push('/');
  };

  const unauthorizedUserDetected = () => {
    toast.error('You are not authorized for this job. Please choose another job.', { autoClose: 10000 });
    setPage(<UnauthorizedJob />);
    // history.push('/');
  };

  useEffect(() => {
    if (CurrStatusMonth !== monthEndDate && !toast.isActive('statusMonthWarning') && getLastIndex(match.path.split('/')) !== 'JobInformation') {
      toast.warn(
        `The month you are currently working in does not match the current status month [${CurrStatusMonth}].
      Verify you are working in the correct month before making changes.`,
        {
          position: 'top-left',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'dark',
          toastId: 'statusMonthWarning'
        }
      );
    }
    if ((CurrStatusMonth === monthEndDate || getLastIndex(match.path.split('/')) === 'JobInformation') && toast.isActive('statusMonthWarning')) {
      toast.dismiss('statusMonthWarning');
    }
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [monthEndDate]);

  useEffect(() => {
    const RECORDID = match.params.id;
    if (RECORDID !== '') {
      getSecurityLevel(user, RECORDID).then((sl) => {
        const securityLevel = sl || 'Unauthorized';
        setSecurityLevel(securityLevel);
        getPreferences(user, securityLevel).then((prefs) => {
          if (mounted.current) setPreferences(prefs);
        });
        if (Unauthorized(securityLevel)) unauthorizedUserDetected();
      });
    }
  }, [user]);

  useEffect(() => {
    const RECORDID = match.params.id;
    try {
      if (CurrentJob?.id !== RECORDID && !firestore._terminated) {
        const baseJobNumber = RECORDID.split('.').filter((part) => part.length === 6)[0];
        if (!existsWithLength(baseJobNumber)) {
          invalidJobDetected();
        } else {
          onSnapshot(
            doc(firestore, 'ENT-Jobs', RECORDID),
            (d) => {
              if (d.exists() && mounted.current) {
                const record = d.data();
                if (mounted.current) setCurrentJob(record);
                const jobDefaults = JSON.parse(JSON.stringify(Defaults));
                ['ContractGLRate', 'RetentionPercent', 'DSOModel', 'LaborEquipOther', 'Materials', 'Subs', 'Variance', 'StatusMonth'].forEach(
                  (def) => (jobDefaults[def] = record[def] || Defaults[def])
                );
                if (mounted.current) setDefaults(jobDefaults);
              }
            },
            (err) => {
              toast.error(err.message);
            }
          );
        }
      }
    } catch (err) {
      toast.error(err.message);
    }
  }, [match, SecurityLevel, user]);

  useEffect(() => {
    // check if previous month has been memorialized
    const prevMonth = getPrevMonth(monthEndDate);
    if (!firestore._terminated) {
      getDoc(doc(firestore, `ENT-Jobs/${match.params.id}/MonthEnd/${prevMonth}`)).then((d) => {
        if (!d.exists() && mounted.current) setPrevMemorialized(false);
        else {
          const data = d.data();
          if (existsWithLength(data) && data.Memorialized === 'true' && mounted.current) setPrevMemorialized(true);
          else if (mounted.current) setPrevMemorialized(false);
        }
      });
    }
  }, [monthEndDate]);

  useEffect(() => {
    let result;
    let memorial = false;
    if (SecurityLevel === 'Unauthorized') return;
    if (!firestore._terminated) {
      onSnapshot(doc(firestore, `ENT-Jobs/${match.params.id}/MonthEnd/${monthEndDate}`), (d) => {
        getMostRecentMonth(match.params.id, monthEndDate).then((mrm) => {
          if (mrm === '2010-12-31') {
            setFirstMonth(true);
          } else {
            setFirstMonth(false);
          }
          if (!d.exists() && EditorUp(SecurityLevel) && mounted.current && mrm !== '2010-12-31') {
            setMostRecentMonth(mrm);
          }
        });
        const data = d.data();
        if (allPartsExistWithLength(data, 'Memorialized')) memorial = data.Memorialized === 'true';
        else memorial = false;
        setMemorialized(memorial);
        switch (getLastIndex(match.url.split('/'))) {
          case 'Forecasting':
            result = [
              <Forecasting
                Transaction={CurrentJob}
                jctdscid={match.params.id}
                monthEndDate={monthEndDate}
                pm={CurrentJob?.ProjectManager || ''}
                memorialized={memorial}
                prevMemorialized={prevMemorialized}
                NextFromStatusMonth={NextFromStatusMonth}
                IsStatusMonth={IsStatusMonth}
              />,
              'Forecasting'
            ];
            break;
          case 'JobInformation':
            result = [<JobDetails Transaction={CurrentJob} onChange={onChange} />, 'Job Information'];
            break;
          case 'Agenda':
            result = [<Agenda jctdscid={match.params.id} />, 'Status Agenda'];
            break;
          case 'Underbilling':
            result = [
              <UnderOverBilling
                Transaction={CurrentJob}
                onChange={onChange}
                monthEndDate={monthEndDate}
                memorialized={memorial}
                jctdscid={match.params.id}
                prevMemorialized={prevMemorialized}
                NextFromStatusMonth={NextFromStatusMonth}
                isStatusMonth={IsStatusMonth}
              />,
              'Underbilling'
            ];
            break;
          case 'Budgets':
            result = [
              <ChangeOrder
                Transaction={CurrentJob}
                monthEndDate={monthEndDate}
                memorialized={memorial}
                history={history}
                jctdscid={match.params.id}
                prevMemorialized={prevMemorialized}
                NextFromStatusMonth={NextFromStatusMonth}
                isStatusMonth={IsStatusMonth}
              />,
              'Budgets'
            ];
            break;
          case 'RevisedBudget':
            result = [<RevisedBudget jctdscid={match.params.id} history={history} Transaction={CurrentJob} monthEndDate={monthEndDate} />, 'Budgets'];
            break;
          case 'BudgetView':
            result = [
              <ChangeOrderForm
                Transaction={CurrentJob}
                jctdscid={match.params.id}
                changeOrderId={match.params.bid}
                monthEndDate={monthEndDate}
                memorialized={memorial}
                history={history}
                prevMemorialized={prevMemorialized}
                NextFromStatusMonth={NextFromStatusMonth}
                isStatusMonth={IsStatusMonth}
              />,
              'Budgets',
              true
            ];
            break;
          case 'FinancialWorkbook':
            result = [<FinancialWorkbook jctdscid={match.params.id} monthEndDate={monthEndDate} />, 'Financial Workbook'];
            break;
          case 'StatusReport':
            result = [
              <ProjectStatus Transaction={CurrentJob} monthEndDate={monthEndDate} memorialized={memorial} jctdscid={match.params.id} />,
              'Project Status Report'
            ];
            break;
          case 'CashFlow':
            result = [<CashFlow Transaction={CurrentJob} monthEndDate={monthEndDate} memorialized={memorial} />, 'Cash Flow'];
            break;
          case 'UserAssignment':
            result = [<UserAssignmentJob Transaction={CurrentJob} />, 'User Assignment'];
            break;
          case 'ActionItems':
            result = [
              <ActionItems
                jctdscid={match.params.id}
                memorialized={memorial}
                monthEndDate={monthEndDate}
                prevMemorialized={prevMemorialized}
                NextFromStatusMonth={NextFromStatusMonth}
                isStatusMonth={IsStatusMonth}
              />,
              'Action Items'
            ];
            break;
          case 'Notes':
            result = [
              <NotesList
                jctdscid={match.params.id}
                memorialized={memorial}
                monthEndDate={monthEndDate}
                prevMemorialized={prevMemorialized}
                NextFromStatusMonth={NextFromStatusMonth}
                isStatusMonth={IsStatusMonth}
              />,
              'Notes'
            ];
            break;
          // case 'CostCodes':
          //   result = [<CostCodes jctdscid={match.params.id} />, 'Cost Codes'];
          //   break;
          case 'Attachments':
            result = [<Attachments jctdscid={match.params.id} />, 'Attachments'];
            break;
          case 'ContractAbstract':
            result = [<ContractAbstractList jctdscid={match.params.id} history={history} />, 'Contract Abstract'];
            break;
          case 'ContractAbstractView':
            result = [
              <ContractAbstract Transaction={CurrentJob} jctdscid={match.params.id} history={history} abstractId={match.params.aid} />,
              'Contract Abstract'
            ];
            break;

          case 'MonthRollback':
            result = [<MonthRollback jctdscid={match.params.id} monthEndDate={monthEndDate} />, 'Rollback Month'];
            break;
          default:
            result = [<Dashboard history={history} />, 'Dashboard'];
        }

        const isClearedToViewPage = MenuCardData.find((mcd) => mcd.title === result[1])?.securityLevel(SecurityLevel, user.beta);

        if (mounted.current) setPage(result[0]);
        if (mounted.current) setTitle(result[1]);
        if (mounted.current) setDisableMonthEnd(result[2] || false);
        // THIS IS THE PROBLEM
        if (isClearedToViewPage) {
          if (mounted.current) setPage(result[0]);
          if (mounted.current) setTitle(result[1]);
          if (mounted.current) setDisableMonthEnd(result[2] || false);
        } else if (mounted.current) {
          if (mounted.current) setPage(<Dashboard history={history} />);
          if (mounted.current) setTitle('Dashboard');
          if (mounted.current) setDisableMonthEnd(false);
        }
      });
    }
  }, [CurrentJob, monthEndDate, SecurityLevel, match, user, prevMemorialized, NextFromStatusMonth, IsStatusMonth]);

  useEffect(() => {
    if (Clickstream.steps[Clickstream.currentStep]?.text !== 'Budgets' || !match.url.includes('Budget')) {
      if (Clickstream.currentStep >= 0 && inAgenda) history.push(`/Job/${match.params.id}/${Clickstream.steps[Clickstream.currentStep].key}`);
      else if (inAgenda) history.push(`/Job/${match.params.id}/Agenda`);
    }
  }, [Clickstream]);

  const switchSubJob = (event) => {
    if (getLastIndex(match.url.split('/')) === match.params.id) history.push(event.target.value);
    else history.push(`/Job/${event.target.value}/${getLastIndex(match.url.split('/'))}`);
  };

  const showMonthEndOpts = () =>
    [
      'Forecasting',
      'Underbilling',
      'Financial Workbook',
      'Project Status Report',
      'Budgets',
      'Cash Flow',
      'Action Items',
      'Status Agenda',
      'Notes',
      'CostCodes'
    ].includes(title) && !inAgenda;

  return (
    <ErrorBoundary componentName={`Job Information / ${match.params.id} / ${title}`}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          overflowX: 'hidden',
          height: '100%',
          border: inAgenda && '3px double gray',
          backgroundColor: inAgenda && 'white',
          boxShadow: inAgenda && '3px 3px lightgray'
        }}
      >
        {!inAgenda && (
          <>
            <MiniSidebar history={history} />
            <QuickActions jctdscid={match.params.id} memorialized={memorialized} isStatusMonth={IsStatusMonth} />
            {getLastIndex(match.path.split('/')) !== 'JobInformation' && (
              <NewMonthModal
                jctdscid={match.params.id}
                monthEndDate={monthEndDate}
                setMostRecentMonth={setMostRecentMonth}
                mostRecentMonth={mostRecentMonth}
                match={match}
              />
            )}
          </>
        )}
        {inClickstream && <ClickstreamControlBar />}
        <Grid
          container
          style={{
            backgroundColor: 'white',
            padding: '8px',
            marginLeft: '8px',
            borderRadius: '4px',
            height: 'min-content',
            overflow: 'auto'
          }}
          id='PAGETOP'
        >
          <Grid item xs={12} className='aldg-gridNoPadding' style={{ position: 'sticky', top: '-10px', zIndex: 500, backgroundColor: 'white' }}>
            <Grid
              container
              style={{
                padding: inAgenda ? '0px 0px 16px 0px' : '16px 0px',
                marginTop: showMonthEndOpts() && '-16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                fontSize: '20px',
                fontWeight: 'bold'
              }}
            >
              {existsWithLength(CurrentJob) && (
                <>
                  <JobSwitcher title={title} match={match} />
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ width: '25%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', paddingTop: matches && '16px' }}
                  >
                    {showMonthEndOpts() && <MonthEndOpts monthEndDate={monthEndDate} setMonthEndDate={setMonthEndDate} disabled={DisableMonthEnd} />}
                    {inClickstream && (
                      <div>
                        <Typography variant='h5' style={{ textAlign: 'right', fontWeight: 'bold', width: '100%', fontStyle: 'italic' }}>
                          Status Presentation in Progress
                        </Typography>
                        <Typography style={{ textAlign: 'right', width: '100%' }}>
                          {Clickstream.activeTaskName}: {monthEndDate}
                        </Typography>
                      </div>
                    )}
                    {!matches && title === 'Dashboard' && !inAgenda && (
                      <div style={{ width: '100%', whiteSpace: 'nowrap', textAlign: 'right', paddingRight: subjobs.length > 1 ? '24px' : '0px' }}>
                        <Link to='/' style={{ color: 'black' }}>
                          Back to Job List
                        </Link>
                      </div>
                    )}

                    {subjobs.length > 1 && !inAgenda && (
                      <div style={{ width: '100%', paddingLeft: '8px' }}>
                        <InputSelect
                          disableEmptyOption
                          options={subjobs}
                          label='Switch To Subjob'
                          value={match.params.id}
                          name='subjob'
                          onChange={switchSubJob}
                        />
                      </div>
                    )}
                    <DisplayDataPull projectId={config.projectId} recordId={CurrentJob.id} />
                    <DisplayAttachments />
                  </Grid>
                  {CurrStatusMonth !== monthEndDate && (
                    <>
                      <Grid item xs={12} className='aldg-gridNoPadding' style={{ height: '20px' }}>
                        <Alert severity='warning' style={{ marginTop: '15px', marginBottom: '15px' }}>
                          The month you are currently working in does not match the current status month <b>{`[${CurrStatusMonth}]`}</b>.
                          <br />
                          <b>Verify you are working in the correct month before making changes.</b>
                        </Alert>
                      </Grid>
                      <Grid item xs={12} className='aldg-gridNoPadding' style={{ height: '50px' }} />
                    </>
                  )}
                  {firstMonth ? (
                    <Alert severity='info' style={{ marginTop: '15px', marginBottom: '15px' }}>
                      This is the first month of the job or an earlier month that has nothing to pull across from.
                    </Alert>
                  ) : null}
                </>
              )}
            </Grid>
          </Grid>
          <Suspense fallback={<Fallback />}>
            <Grid item xs={12} className='aldg-gridNoPadding'>
              {page}
            </Grid>
          </Suspense>
        </Grid>
      </div>
    </ErrorBoundary>
  );
};

JobInformation.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default JobInformation;
