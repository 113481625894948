/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Grid,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Tooltip,
  LinearProgress
} from '@mui/material';
import { collection, doc, getDoc, getDocs, setDoc, writeBatch, onSnapshot } from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NaNtoNum, useFunctionCall } from '@aldridge/aldg-helpers';
import PropTypes from 'prop-types';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowRotateBack,
  faArrowUpRightFromSquare,
  faCheck,
  faLock,
  faPresentationScreen,
  faScroll,
  faX
} from '@fortawesome/pro-regular-svg-icons';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import existsWithLength from '../utils/existsWithLength';
import { _CurrentJob, _Defaults, _GridPagination, _CostDiffThreshold, _MonthEndDate, _StatusMonth } from '../_Recoil/atoms';
import { firestore } from '../firebase';
import ErrorBoundary from '../_GlobalComponents/ErrorBoundary';
import MonthEndOpts from '../Components/UIComponents/MonthEndOpts';
import { formatAsCurrency } from '../Components/UIComponents/format';
import { UserContext } from '../providers/UserProvider';

const JobsToStatus = (props) => {
  const { setEditing, history } = props;
  const user = useContext(UserContext);
  const GridApiRef = useGridApiRef();
  const GridPagination = useRecoilValue(_GridPagination);
  const [CostDiffThreshold, setCostDiffThreshold] = useState({});
  const StatusMonth = useRecoilValue(_StatusMonth);
  const [MonthEnd, setMonthEnd] = useState(StatusMonth);
  const setJobMonthEnd = useSetRecoilState(_MonthEndDate);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [rowLoading, setRowLoading] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, loading, error, functionCall } = useFunctionCall(`CostToDate`);
  const {
    data: jobstostatusdata,
    loading: jobstostatusloading,
    error: jobstostatuserror,
    functionCall: JobsToStatusFunc
  } = useFunctionCall(`callJobsToStatus`);

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);
  const columns = [
    {
      field: 'JobNumber',
      headerName: 'Job Number',
      valueGetter: (params) => `${params.row.JobNumber}${existsWithLength(params.row.SubJobNumber) ? `.${params.row.SubJobNumber}` : ''}`,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'DivisionNumer',
      headerName: 'Division Number',
      valueGetter: (params) => `${params.row.Division}`,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'ProjectDescription',
      headerName: 'Description',
      valueGetter: (params) => params.row.JobDescription,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'CurrCTD',
      headerName: 'Current Cost to Date',
      valueGetter: (params) => params.row.CurrentMonthCosts,
      valueFormatter: (params) => formatAsCurrency(params.value),
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'PrevCTD',
      headerName: 'Previous Cost to Date',
      valueGetter: (params) => params.row.PreviousMonthCosts,
      valueFormatter: (params) => formatAsCurrency(params.value),
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Difference',
      headerName: 'Cost Difference',
      valueGetter: (params) => params.row.CostDifference,
      valueFormatter: (params) => formatAsCurrency(params.value),
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Attending',
      headerName: 'Attending Status',
      type: 'singleSelect',
      valueOptions: ['Present', 'Submit', 'No'],
      valueGetter: (params) => {
        if (['Present', 'Attending'].includes(params.row.AttendingStatus)) return 'Present';
        if (['Submit', 'Paper'].includes(params.row.AttendingStatus)) return 'Submit';
        return 'No';
      },
      renderCell: (params) => {
        if (params.value === 'No') return <FontAwesomeIcon icon={faX} color='red' />;
        return params.value === 'Present' ? (
          <FontAwesomeIcon icon={faPresentationScreen} color='green' />
        ) : (
          <FontAwesomeIcon icon={faScroll} color='green' />
        );
      },
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'FinalStatus',
      headerName: 'Final Status?',
      type: 'singleSelect',
      valueOptions: ['Yes', 'No'],
      valueGetter: (params) => params.row.FinalStatus,
      renderCell: (params) => {
        if (params.value === 'Yes')
          return (
            <Tooltip title={`${params.row?.FinalStatusSetDate || ''}`} placement='bottom'>
              <Typography variant='body2' color='#274e13'>
                Yes
              </Typography>
            </Tooltip>
          );
        return (
          <Typography variant='body2' color='#660000'>
            No
          </Typography>
        );
      },
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Memorialized',
      headerName: 'Memorialized',
      valueGetter: (params) => params.row.Memorialized,
      renderCell: (params) => {
        if (params.value === 'false') return <FontAwesomeIcon icon={faX} color='red' />;
        if (params.value === 'true') return <FontAwesomeIcon icon={faCheck} color='green' />;
        return null;
      },
      flex: getFlex(15),
      width: getWidth(300),
      type: 'singleSelect',
      valueOptions: ['true', 'false']
    },
    {
      field: 'MemorializedDate',
      headerName: 'Memorialized Date',
      valueGetter: (params) => (existsWithLength(params.row.MemorializedDate) ? dayjs(params.row.MemorializedDate).format('YYYY-MM-DD hh:mm a') : ''),
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'ViewJob',
      headerName: 'View Job',
      renderCell: (params) => <FontAwesomeIcon icon={faArrowUpRightFromSquare} onClick={() => history.push(`/Job/${params.row.JobID}`)} />,
      flex: getFlex(15),
      width: getWidth(200)
    }
  ];

  const generating = useRef({ loading: false, refresh: true, previous: true });

  useEffect(() => {
    getDoc(doc(firestore, `CompanyDefaults/JobsToStatus`)).then((d) => {
      setCostDiffThreshold(d.data());
    });
  }, []);

  useEffect(() => {
    if (MonthEnd) {
      setRows([]);
      setRowLoading(true);
      getDoc(doc(firestore, 'JobsComingToStatus', MonthEnd)).then((a) => {
        if (a.exists()) {
          const rec = a.data();
          toast.success(
            `Cost Details Pulled as of ${rec.CalculatedAt ? dayjs(rec.CalculatedAt).format('MMM DD, YYYY hh:mm A') : 'Unknown Time'} by ${
              rec.CalculatedBy || 'Unknown User'
            }`,
            { autoClose: 10000 }
          );
          onSnapshot(collection(firestore, `JobsComingToStatus/${MonthEnd}/Jobs`), (snapshot) => {
            const all = [];
            snapshot.forEach((d) => {
              all.push(d.data());
            });

            all.forEach((r) => {
              getDoc(doc(firestore, `ENT-Jobs/${r.JobID}/MonthEnd`, MonthEnd)).then((j) => {
                if (j.exists()) {
                  const jobRec = j.data();
                  r.Memorialized = jobRec.Memorialized || 'false';
                  r.MemorializedDate = jobRec.MemorializedDate || '';
                } else {
                  r.Memorialized = r.Memorialized || 'false';
                  r.MemorializedDate = r.MemorializedDate || '';
                }
              });
            });
            setRows(all);
            setRowLoading(false);
          });
        } else {
          setRows([]);
          setRowLoading(false);
        }
      });
    }
  }, [MonthEnd]);

  useEffect(() => {
    const calculateCostData = async (d) => {
      let batch = writeBatch(firestore);
      for (let i = 0; i < d.length; i++) {
        const r = d[i];
        if (i % 500 === 0 && i > 0) {
          // eslint-disable-next-line no-await-in-loop
          await batch.commit();
          batch = writeBatch(firestore);
        }
        const docRef = doc(firestore, `JobsComingToStatus/${MonthEnd}/Jobs`, r.jctdscid);
        const f = rows.filter((row) => row.jctdscid === r.jctdscid);
        if (typeof f[0]?.AttendingStatus === 'undefined') {
          if (r.CostDifference > CostDiffThreshold.CostDiffUpper) {
            r.AttendingStatus = 'Present';
          } else if (r.CostDifference > CostDiffThreshold.CostDiffLower) {
            r.AttendingStatus = 'Submit';
          } else {
            r.AttendingStatus = 'No';
          }
        } else {
          r.AttendingStatus = f[0]?.AttendingStatus;
        }
        r.FinalStatus = f[0]?.FinalStatus || 'No';
        r.FinalStatusSetDate = f[0]?.FinalStatusSetDate || '';
        batch.set(docRef, r, { merge: true });
      }
      await batch.commit();
      d.forEach((r) => {
        getDoc(doc(firestore, `ENT-Jobs/${r.JobID}/MonthEnd`, MonthEnd)).then((j) => {
          if (j.exists()) {
            const jobRec = j.data();
            setRows((prev) => [...prev, { ...r, Memorialized: jobRec.Memorialized || 'false', MemorializedDate: jobRec.MemorializedDate || '' }]);
          } else {
            setRows((prev) => [...prev, { ...r, Memorialized: 'false', MemorializedDate: '' }]);
          }
        });
      });
    };
    if (data) {
      calculateCostData(data);
    }
  }, [data, error, loading]);
  // const setupPullFunc = (jid, attenStatus) =>
  //   new Promise((resolve, reject) => {
  //     try {
  //       console.log('Setting up Pull Function', jid, attenStatus);
  //       generating.current.loading = true;
  //       getMostRecentMonth(jid, MonthEnd).then((mrm) => {
  //         // console.log(jid, mrm);
  //         const RefreshForecast = httpsCallable(functions, 'JobsToStatusForecastData');
  //         RefreshForecast(
  //           {
  //             jctdscid: jid,
  //             monthEndDate: MonthEnd
  //           },
  //           { timeout: 600000 }
  //         )
  //           .then(
  //             (result) => {
  //               console.log(result);
  //               resolve({
  //                 jbid: jid,
  //                 data: result.data.message.forecast,
  //                 collections: result.data.message.collections,
  //                 mostRecMonth: mrm,
  //                 autoGen: !['Present', 'Attending', 'Submit', 'Paper'].includes(attenStatus)
  //               });
  //             },
  //             (e) => {
  //               console.log(e);
  //               setStartMonthProgress(0);
  //               toast.error(`An Error was encountered, please try again.`);
  //             }
  //           )
  //           .catch((e) => {
  //             console.log(`Encountered Error with function: ${e}`);
  //           });
  //       });
  //     } catch (err) {
  //       console.log(err);
  //       reject();
  //     }
  //   });

  const handleRenderData = () => {
    if (MonthEnd) {
      functionCall({ monthEndDate: MonthEnd });
      const docRef = doc(firestore, 'JobsComingToStatus', MonthEnd);
      setDoc(docRef, { CalculatedAt: new Date().toJSON(), CalculatedBy: user.email }, { merge: true }).catch((err) => {
        toast.error(err);
      });
    } else {
      toast.error('Please select a month end date');
    }
  };

  const handleCellChange = (params) => {
    if (!['Present', 'Attending', 'FinalStatus', 'Memorialized'].includes(params.field)) return;
    const d = params.row;
    const docRef = doc(firestore, `JobsComingToStatus/${MonthEnd}/Jobs`, params.row.jctdscid);
    if (['Present', 'Attending'].includes(params.field)) {
      if (params.value === false || params.value === 'No') {
        d.AttendingStatus = 'Present';
      } else if (['Present', 'Attending'].includes(params.value)) {
        d.AttendingStatus = 'Submit';
      } else {
        d.AttendingStatus = 'No';
      }
    }
    if (params.field === 'FinalStatus') {
      if (params.value === 'Yes') {
        d.FinalStatus = 'No';
      } else {
        d.FinalStatus = 'Yes';
        d.FinalStatusSetDate = new Date().toJSON();
      }
    }
    if (params.field === 'Memorialized') {
      if (params.value === false || params.value === 'false') {
        d.Memorialized = 'true';
        d.MemorializedDate = new Date().toJSON();
      } else {
        d.Memorialized = 'false';
        d.MemorializedDate = '';
      }
      setDoc(
        doc(firestore, `ENT-Jobs/${d.jctdscid}/MonthEnd/${MonthEnd}`),
        {
          Memorialized: params.value ? 'true' : 'false',
          MemorializedBy: user.email,
          MemorializedDate: new Date().toJSON(),
          MemorializedOnPage: 'JobsToStatus'
        },
        { merge: true }
      );
    }
    setRows((prev) => {
      const uRecord = prev.findIndex((r) => r.jctdscid === params.id);
      const cloned = JSON.parse(JSON.stringify(prev));
      if (uRecord > -1) {
        cloned[uRecord] = d;
      }
      return cloned;
    });
    setDoc(docRef, d, { merge: true }).catch((err) => {
      toast.error(err);
    });
  };

  // const SendingStubs = (jid, monthEnd, mostRecentMonth) => {
  //   console.log(`Sending stub to PMs for ${jid}`);
  // };

  // const handleBeginMonth = (arr) =>
  //   // eslint-disable-next-line no-async-promise-executor
  //   new Promise(async (resolve, reject) => {
  //     const promises = [];
  //     setOpen(false);
  //      let count = 0;
  //         for (let i = 0; i < arr.length; i++) {
  //           count += 1;
  //           promises.push(setupPullFunc(arr[i].JobID, arr[i].AttendingStatus));
  //         }
  //     console.log(promises);
  //     Promise.all(promises).then(async (res) => {
  //       console.log(res);
  //       const promises2 = [];
  //       // for (let i = 0; i < res.length; i++) {
  //       //   console.log(res[i].jbid, res[i].collections);
  //       //   promises2.push(
  //       //     pullFromPrevious(res[i].jbid, MonthEnd, res[i].mostRecMonth, () => {}, generating, res[i].data, user, false, res[i].collections)
  //       //   );
  //       // }
  //       Promise.all(promises2).then(() => {
  //         // toast.success(`Completed Pulling Previous Month for ${arr.length} jobs`);
  //         setStartMonthProgress(0);
  //         setPullingMonthsModal(false);
  //         const promises3 = [];
  //         res.forEach((r) => {
  //           if (r.autoGen) {
  //             promises3.push(
  //               AutoCalculateForecast(
  //                 r.jbid,
  //                 MonthEnd,
  //                 r.mostRecMonth,
  //                 () => {},
  //                 () => {},
  //                 {},
  //                 false
  //               )
  //             );
  //             promises3.push(
  //               setDoc(
  //                 doc(firestore, `ENT-Jobs/${r.jbid}/MonthEnd/${MonthEnd}`),
  //                 { Memorialized: 'true', MemorializedDate: new Date().toJSON() },
  //                 { merge: true }
  //               )
  //             );
  //           } else {
  //             promises3.push(() => {
  //               // Handle sending stub notification PMs
  //               SendingStubs(r.jbid, MonthEnd, r.mostRecMonth);
  //             });
  //           }
  //         });
  //         Promise.all(promises3).then(() => {
  //           // toast.success(`Cost to complete auto generated for ${arr.filter((a) => a.AttendingStatus === false).length} job(s) not attending status`);
  //           // toast.success(`Sending Notification to PMs for ${arr.filter((a) => a.AttendingStatus === true).length} job(s) attending status`);
  //           generating.current.loading = false;
  //           resolve();
  //         });
  //       });
  //     });
  //   });

  // const batchJobs = (index) => {
  //   setPullingMonthsModal(true);
  //   const firstPromises = [];
  //   const batchSize = 20;
  //   let batch = [];
  //   setStartMonthProgress(((index === 0 ? 0.01 : index) / rows.length) * 100);
  //   if (index + batchSize < rows.length) batch = rows.slice(index, index + batchSize);
  //   else batch = rows.slice(index, rows.length);
  //   /* ** TEST LINE ** */
  //   firstPromises.push(handleBeginMonth(rows[0]));
  //   Promise.all(firstPromises).then(() => {
  //     // console.log(`Batch of index: [${index}] completed`);
  //     // if (index + batchSize < rows.length) {
  //     //   batchJobs(index + batchSize);
  //     // } else {
  //     toast.success(`${rows.length} Jobs Completed`);
  //     setPullingMonthsModal(false);
  //     setStartMonthProgress(0);
  //     // }
  //   });
  // };

  const toggleMemorialized = async () => {
    const selectedItems = Array.from(GridApiRef.current.getSelectedRows(), ([key, value]) => ({ key, value }));
    const segmentIds = selectedItems.map((i) => i.key.toString());
    const updatedRows = rows.map((row) => {
      if (segmentIds.includes(row.jctdscid)) {
        return {
          ...row,
          Memorialized: row.Memorialized === 'true' ? 'false' : 'true',
          MemorializedDate: row.Memorialized === 'true' ? '' : new Date().toJSON()
        };
      }
      return row;
    });
    setRows(updatedRows);
    const batch = writeBatch(firestore);
    selectedItems.forEach((segment) => {
      const docRef = doc(firestore, `ENT-Jobs/${segment.value.JobID}/MonthEnd/${MonthEnd}`);
      const newSegment = {
        ...segment.value,
        Memorialized: segment.value.Memorialized === 'true' ? 'false' : 'true',
        MemorializedBy: user.email,
        MemorializedDate: segment.value.Memorialized === 'true' ? '' : new Date().toJSON(),
        MemorializedOnPage: 'JobsToStatus'
      };
      batch.set(docRef, newSegment, { merge: true });
    });
    await batch.commit();
  };

  const BeginNewMonth = () => {
    JobsToStatusFunc({ MonthEnd, user: { name: user.name, email: user.email } });
    setOpen(false);
  };

  return (
    <ErrorBoundary componentName='Jobs Table'>
      <Grid container marginTop='10px'>
        <Grid item xs={3}>
          <MonthEndOpts
            monthEndDate={MonthEnd || StatusMonth}
            setMonthEndDate={(val) => {
              setMonthEnd(val);
              setJobMonthEnd(val);
            }}
            disabled={loading || rowLoading}
            disableEmpty={false}
          />
        </Grid>
        {MonthEnd ? (
          <Grid item xs={2}>
            <ToolTipIconButton
              icon={faArrowRotateBack}
              // style={{ marginLeft: 20, paddingRight: 25 }}
              color='primary'
              variant='contained'
              onClick={handleRenderData}
              loading={loading || generating.current.loading || rowLoading}
              label='Calculate Costs'
            />
          </Grid>
        ) : (
          <Grid item xs={2} />
        )}
        {rows.length > 0 || !loading ? (
          <Grid item xs={3}>
            <ToolTipIconButton
              icon={faArrowDown}
              color='primary'
              variant='contained'
              onClick={() => setOpen(true)}
              label='Begin Month'
              loading={generating.current.loading || rowLoading}
            />
          </Grid>
        ) : (
          <Grid item xs={3} />
        )}
        <Grid item xs={3}>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12}>
              <FontAwesomeIcon icon={faScroll} color='green' /> Submit
            </Grid>
            <Grid item xs={12}>
              <FontAwesomeIcon icon={faPresentationScreen} color='green' /> Present
            </Grid>
            <Grid item xs={12}>
              <FontAwesomeIcon icon={faX} color='red' /> Not Attending
            </Grid>
          </Grid>{' '}
        </Grid>
        <Grid item xs={1} paddingLeft='30px'>
          <Grid container justifyContent='space-between'>
            <Grid item xs={6}>
              <ToolTipIconButton
                icon={faLock}
                color='primary'
                variant='contained'
                onClick={toggleMemorialized}
                tooltipText='Select Jobs to Toggle Memorialize Status'
                loading={generating.current.loading || loading || rowLoading}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <ToolTipIconButton
                icon={faArrowRotateBack}
                color='primary'
                variant='contained'
                onClick={refreshAllForecastData}
                tooltipText='Refresh All Forecast Data'
                loading
              /> 
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress variant='determinate' value={(rows.filter((r) => r.Processed === true).length / rows.length) * 100} />
          <div>{Math.round(NaNtoNum(rows.filter((r) => r.Processed === true).length / rows.length) * 100)}%</div>
        </Grid>
        <Grid item xs={12}>
          <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>Would you like to open this month for the following?</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description' color='#3f3f3f'>{`[${
                rows.filter((a) => ['Present', 'Attending'].includes(a.AttendingStatus)).length
              }] Jobs Presenting Live`}</DialogContentText>
              <DialogContentText id='alert-dialog-description' color='#3f3f3f'>{`[${
                rows.filter((a) => ['Paper', 'Submit'].includes(a.AttendingStatus)).length
              }] Jobs Presenting Paper`}</DialogContentText>
              <DialogContentText id='alert-dialog-description' color='#3f3f3f'>{`[${
                rows.filter((a) => a.AttendingStatus === 'No').length
              }] Jobs Not Called to Status`}</DialogContentText>
              {/* <DialogContentText id='alert-dialog-description'>(Jobs not in Status will have an auto completed Cost to Complete)</DialogContentText> */}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'right' }}>
              <Button
                onClick={() => setOpen(false)}
                variant='contained'
                color='error'
                style={{ marginRight: '50px', marginBottom: '20px' }}
                autoFocus
              >
                No
              </Button>
              <Button onClick={() => BeginNewMonth()} variant='contained' color='success' style={{ marginRight: '30px', marginBottom: '20px' }}>
                {/* <Button onClick={() => batchJobs(0)} variant='contained' color='success' style={{ marginRight: '30px', marginBottom: '20px' }}> */}
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <AldgDataGrid
            containerHeight='700px'
            pagination={GridPagination}
            columns={columns}
            rows={rows}
            getRowId={(r) => r.jctdscid}
            setEditingOverride={setEditing}
            onCellClick={handleCellChange}
            checkboxSelection
            apiRef={GridApiRef}
            loading={loading || rowLoading}
          />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

JobsToStatus.propTypes = { setEditing: PropTypes.func, history: PropTypes.object.isRequired };
JobsToStatus.defaultProps = { setEditing: () => {} };

export default JobsToStatus;
