import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Modal, Typography } from '@mui/material';
import { collection, orderBy, query, getDocs } from 'firebase/firestore';
import { useRecoilState, useRecoilValue } from 'recoil';
import existsWithLength from '../../../utils/existsWithLength';
import NaNtoZero from '../../../utils/convertNaNToZero';
import {
  _Clickstream,
  _LaborForecastingGridState,
  _MaterialForecastingGridState,
  _SubcontractorForecastingGridState,
  _EquipmentForecastingGridState,
  _OtherForecastingGridState,
  _SecurityLevel
} from '../../../_Recoil/atoms';
import convertCostTypeRToE from '../../../utils/convertCostTypeRToE';
import ForecastingToolbar from './ForecastingToolbar';
import { firestore } from '../../../firebase';
import ForecastingDataGrid from './ForecastingDataGrid';
import { AdminUp, EditorUp } from '../UserAssignment/checkSecurity';
import GrossProfitReports from '../ProjectStatus/GrossProfitReports';
import { StatusContext } from '../../../providers/StatusProvider';
import ContractDetails from '../ProjectStatus/ContractDetails';

const Forecasting = (props) => {
  const { Transaction, jctdscid, monthEndDate, pm, memorialized, prevMemorialized, NextFromStatusMonth, IsStatusMonth } = props;
  const [tableLoading, setTableLoading] = useState(true);
  const [UploadingForecast, setUploadingForecast] = useState(false);
  const [rows, setRows] = useState([]);
  const SecurityLevel = useRecoilValue(_SecurityLevel);
  const Clickstream = useRecoilValue(_Clickstream);
  const [LaborForecastingGridState, setLaborForecastingGridState] = useRecoilState(_LaborForecastingGridState);
  const [MaterialForecastingGridState, setMaterialForecastingGridState] = useRecoilState(_MaterialForecastingGridState);
  const [SubcontractorForecastingGridState, setSubcontractorForecastingGridState] = useRecoilState(_SubcontractorForecastingGridState);
  const [EquipmentForecastingGridState, setEquipmentForecastingGridState] = useRecoilState(_EquipmentForecastingGridState);
  const [OtherForecastingGridState, setOtherForecastingGridState] = useRecoilState(_OtherForecastingGridState);
  const inAgenda = Clickstream.inAgenda;

  const NextMonthEdit = prevMemorialized && monthEndDate === NextFromStatusMonth; // Can edit next month if status month is memorialized
  const StatusMonthEdit = IsStatusMonth || NextMonthEdit || AdminUp(SecurityLevel); // Can edit if status month or above condition (or admin)
  const canEdit = !inAgenda && !memorialized && StatusMonthEdit && EditorUp(SecurityLevel);

  const { budgets, contractValues } = useContext(StatusContext);
  const mounted = useRef(null);

  const loadForecastData = () => {
    if (monthEndDate !== null && monthEndDate !== '' && mounted.current) {
      setTableLoading(true);
      getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/Forecasting`), orderBy('PNP'), orderBy('Picc'))).then(
        (snap) => {
          const forecasts = [];
          if (snap.empty && mounted.current) {
            setRows([]);
            setTableLoading(false);
          } else {
            snap.forEach((d) => {
              const picc = d.data();
              const addedID = { ...picc };
              addedID.id = d.id;
              forecasts.push(addedID);
            });
            if (mounted.current) {
              setRows(forecasts);
              setTableLoading(false);
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    mounted.current = true;
    loadForecastData();
    return () => {
      mounted.current = false;
    };
  }, [monthEndDate, jctdscid]);

  const createRows = (allRows, costType) => {
    const rowSet = allRows.filter((row) => costType.includes(convertCostTypeRToE(row.CostType)));
    if (rowSet.length === 0) return [];
    return rowSet;
  };

  const laborRows = createRows(rows, ['L']);
  const materialRows = createRows(rows, ['M']);
  const subcontractorRows = createRows(rows, ['S']);
  const equipmentRows = createRows(rows, ['E']);
  const otherRows = createRows(rows, ['O']);

  const createTotalRow = (costType, rowSet, labor) => ({
    PiccDescription: costType,
    LaborRate: '-',
    LaborHours: rowSet.reduce((totalLaborHours, row) => totalLaborHours + NaNtoZero(row.LaborHours), 0),
    LaborCost: rowSet.reduce((totalLaborCost, row) => totalLaborCost + NaNtoZero(row.LaborCost), 0),
    LaborBurden: rowSet.reduce((totalLaborBurden, row) => totalLaborBurden + NaNtoZero(row.LaborBurden), 0),
    LaborTotal: rowSet.reduce((totalLaborTotal, row) => totalLaborTotal + NaNtoZero(NaNtoZero(row.LaborHours) * NaNtoZero(row.LaborRate)), 0),
    CostHrToComplete: rowSet.reduce((totalCostHrToComplete, row) => totalCostHrToComplete + NaNtoZero(row.CostHrToComplete), 0) / rowSet.length,
    HoursToComplete: rowSet.reduce((totalHoursToComplete, row) => totalHoursToComplete + NaNtoZero(row.HoursToComplete), 0),
    HoursToDate: rowSet.reduce((totalHoursToDate, row) => totalHoursToDate + NaNtoZero(row.HoursToDate), 0),
    HoursAtComplete: rowSet.reduce((totalHoursAtComplete, row) => totalHoursAtComplete + NaNtoZero(row.HoursAtComplete), 0),
    CostPerHrToDate: rowSet.reduce((totalCostPerHrToDate, row) => totalCostPerHrToDate + NaNtoZero(row.CostPerHrToDate), 0) / rowSet.length,
    CostToDate: rowSet.reduce((totalCostToDate, row) => totalCostToDate + NaNtoZero(row.CostToDate), 0),
    CostToComplete: labor
      ? rowSet.reduce((totalCostToComplete, row) => totalCostToComplete + NaNtoZero(row.HoursToComplete) * NaNtoZero(row.CostHrToComplete), 0)
      : rowSet.reduce((totalCostToComplete, row) => totalCostToComplete + NaNtoZero(row.CostToComplete), 0),
    Total: rowSet.reduce((totalTotal, row) => totalTotal + NaNtoZero(row.Total), 0),
    TotalCostAtCompletion: rowSet.reduce(
      (totalTotalCostAtCompletion, row) =>
        totalTotalCostAtCompletion + (NaNtoZero(row.HoursToComplete) * NaNtoZero(row.CostHrToComplete) + NaNtoZero(row.CostToDate)),
      0
    ),
    TotalCostAtCompletionLastMonth: rowSet.reduce(
      (totalCostAtCompletionLastMonth, row) => totalCostAtCompletionLastMonth + NaNtoZero(row.TotalCostAtCompletionLastMonth),
      0
    ),

    VarianceDifference: rowSet.reduce(
      (varianceDifference, row) => varianceDifference + NaNtoZero(row.VarianceAtCompletion) - NaNtoZero(row.VarianceLastMonth),
      0
    ),

    RevisedBudget: rowSet.reduce((totalRevisedBudget, row) => totalRevisedBudget + NaNtoZero(row.RevisedBudget), 0),
    VarianceAtCompletion: labor
      ? rowSet.reduce(
          (totalVarianceAtCompletion, row) =>
            totalVarianceAtCompletion +
            NaNtoZero(NaNtoZero(row.LaborHours) * NaNtoZero(row.LaborRate)) -
            (NaNtoZero(row.HoursToComplete) * NaNtoZero(row.CostHrToComplete) + NaNtoZero(row.CostToDate)),
          0
        )
      : rowSet.reduce(
          (totalVarianceAtCompletion, row) =>
            totalVarianceAtCompletion + (NaNtoZero(row.Total) - (NaNtoZero(row.CostToComplete) + NaNtoZero(row.CostToDate))),
          0
        ),
    VarianceLastMonth: rowSet.reduce((totalVarLastMonth, row) => totalVarLastMonth + NaNtoZero(row.VarianceLastMonth), 0),
    PNP: '-',
    CustomerCostCode: '-',
    CostType: costType.toString(),
    id: costType.toString()
  });

  const createSummaryTable = () => {
    let plaborTotal = {};
    if (!tableLoading) {
      if (existsWithLength(laborRows)) {
        plaborTotal = createTotalRow(
          'L (P)',
          laborRows.filter((lr) => lr.PNP === 'P'),
          true
        );
        plaborTotal.CostPerHrToDate = plaborTotal.CostToDate / plaborTotal.HoursToDate;
        plaborTotal.CostHrToComplete = plaborTotal.CostToComplete / plaborTotal.HoursToComplete;
        plaborTotal.Picc = 'Labor';
        plaborTotal.Total = plaborTotal.LaborTotal;
        plaborTotal.id = 'L (P)';
        plaborTotal.PNP = 'P';
        plaborTotal.Subtotal = true;
        // plaborTotal.CostToComplete = plaborTotal.HoursToComplete * plaborTotal.CostHrToComplete;
      }

      let nplaborTotal = {};
      if (existsWithLength(laborRows)) {
        nplaborTotal = createTotalRow(
          'L (NP)',
          laborRows.filter((lr) => lr.PNP === 'NP'),
          true
        );
        nplaborTotal.CostPerHrToDate = nplaborTotal.CostToDate / nplaborTotal.HoursToDate;
        nplaborTotal.CostHrToComplete = nplaborTotal.CostToComplete / nplaborTotal.HoursToComplete;
        nplaborTotal.Picc = 'Labor';
        nplaborTotal.Total = nplaborTotal.LaborTotal;
        nplaborTotal.id = 'L (NP)';
        nplaborTotal.PNP = 'NP';
        nplaborTotal.Subtotal = true;
        // nplaborTotal.CostToComplete = nplaborTotal.HoursToComplete * nplaborTotal.CostHrToComplete;
      }

      let materialTotal = {};
      if (existsWithLength(materialRows)) {
        materialTotal = createTotalRow('M', materialRows, false);
        materialTotal.Picc = 'Material';
      }

      let subcontractorTotal = {};
      if (existsWithLength(subcontractorRows)) {
        subcontractorTotal = createTotalRow('S', subcontractorRows, false);
        subcontractorTotal.Picc = 'Subcontractor';
      }

      let equipmentTotal = {};
      if (existsWithLength(equipmentRows)) {
        equipmentTotal = createTotalRow('E', equipmentRows, false);
        equipmentTotal.Picc = 'Equipment';
      }

      let otherTotal = {};
      if (existsWithLength(otherRows)) {
        otherTotal = createTotalRow('O', otherRows, false);
        otherTotal.Picc = 'Other';
      }

      const totalRows = [plaborTotal, nplaborTotal, materialTotal, subcontractorTotal, equipmentTotal, otherTotal];
      return totalRows;
    }
    return [{}, {}, {}, {}, {}, {}];
  };

  const summary = createSummaryTable(rows);

  const mergeRows = (updatedRows) => {
    const uRows = JSON.parse(JSON.stringify(rows));
    updatedRows.forEach((u) => {
      const rowIdx = uRows.findIndex((r) => r.id === u.id);
      if (rowIdx > -1) {
        uRows[rowIdx] = u;
      }
    });
    setRows(uRows);
  };

  return (
    <Paper variant='outlined' className='aldg-field'>
      <Modal open={tableLoading} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant='h6' color='white'>
          Loading...
        </Typography>
      </Modal>
      <Grid container>
        {!inAgenda && (
          <Grid item xs={12}>
            <Grid container alignItems='center' justifyItems='flex-end'>
              <Grid item xs={12}>
                <ForecastingToolbar
                  monthEndDate={monthEndDate}
                  jctdscid={jctdscid}
                  tableLoading={tableLoading}
                  rows={rows}
                  setRows={mergeRows}
                  setUploadingForecast={setUploadingForecast}
                  uploadingForecast={UploadingForecast}
                  pm={pm}
                  memorialized={memorialized}
                  reloadData={loadForecastData}
                  lastUpdated={contractValues?.ForecastLastUpdated}
                  canEdit={canEdit}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <ForecastingDataGrid
          // rows={laborRows}
          rows={laborRows.concat(summary.filter((s) => s?.id?.substring(0, 1) === 'L'))}
          setRows={mergeRows}
          loading={tableLoading || UploadingForecast}
          jctdscid={jctdscid}
          title='Labor'
          editable={canEdit}
          gridAtom={LaborForecastingGridState}
          setGridAtom={setLaborForecastingGridState}
        />
        <ForecastingDataGrid
          rows={materialRows}
          setRows={mergeRows}
          loading={tableLoading || UploadingForecast}
          jctdscid={jctdscid}
          title='Material'
          editable={canEdit}
          gridAtom={MaterialForecastingGridState}
          setGridAtom={setMaterialForecastingGridState}
        />
        <ForecastingDataGrid
          rows={subcontractorRows}
          setRows={mergeRows}
          loading={tableLoading || UploadingForecast}
          jctdscid={jctdscid}
          title='Subcontractor'
          editable={canEdit}
          gridAtom={SubcontractorForecastingGridState}
          setGridAtom={setSubcontractorForecastingGridState}
        />
        <ForecastingDataGrid
          rows={equipmentRows}
          setRows={mergeRows}
          loading={tableLoading || UploadingForecast}
          jctdscid={jctdscid}
          title='Equipment'
          editable={canEdit}
          gridAtom={EquipmentForecastingGridState}
          setGridAtom={setEquipmentForecastingGridState}
        />
        <ForecastingDataGrid
          rows={otherRows}
          setRows={mergeRows}
          loading={tableLoading || UploadingForecast}
          jctdscid={jctdscid}
          title='Other'
          editable={canEdit}
          gridAtom={OtherForecastingGridState}
          setGridAtom={setOtherForecastingGridState}
        />
        <Grid item xs={12} className='aldg-divider' />
        <ForecastingDataGrid rows={summary} loading={tableLoading || UploadingForecast} jctdscid={jctdscid} title='Summary' editable={false} />
        <Grid item xs={12} className='aldg-divider' />
        <GrossProfitReports changeOrders={budgets} forecast={rows} MonthEndContractNumbers={contractValues} />
        <Grid item xs={12} className='aldg-divider' />
        <ContractDetails Transaction={Transaction} MonthEndContractNumbers={contractValues} Type='Forecasting' />
      </Grid>
    </Paper>
  );
};

Forecasting.propTypes = {
  Transaction: PropTypes.object,
  jctdscid: PropTypes.string.isRequired,
  monthEndDate: PropTypes.string,
  pm: PropTypes.string,
  memorialized: PropTypes.bool,
  prevMemorialized: PropTypes.bool,
  NextFromStatusMonth: PropTypes.string.isRequired,
  IsStatusMonth: PropTypes.bool
};
Forecasting.defaultProps = {
  Transaction: {},
  monthEndDate: '2010-12-31',
  pm: '-',
  memorialized: true,
  prevMemorialized: false,
  IsStatusMonth: false
};

export default Forecasting;
