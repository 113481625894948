/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { TextDisplay, ToolTipIconButton } from '@aldridge/aldg-ui-components';
import dayjs from 'dayjs';
import { faBackward } from '@fortawesome/pro-regular-svg-icons';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import { StatusContext } from '../../../providers/StatusProvider';

const MonthRollback = (props) => {
  const { jctdscid, monthEndDate } = props;

  const { contractValues } = useContext(StatusContext);
  const { MonthStartedDate, ForecastLastUpdated, CashflowLastUpdated } = contractValues;

  // const { loading, functionCall, data, error } = useFunctionCall('DeleteMonthRecord', undefined, 540000000);

  // const deleteQueryBatch = async (db, query, resolve) => {
  //   const snapshot = await query.get();

  //   const batchSize = snapshot.size;
  //   if (batchSize === 0) {
  //     resolve();
  //     return;
  //   }

  //   // Delete documents in a batch
  //   const batch = db.batch();
  //   snapshot.docs.forEach((dc) => {
  //     batch.delete(dc.ref);
  //   });
  //   await batch.commit();

  //   process.nextTick(() => {
  //     deleteQueryBatch(db, query, resolve);
  //   });
  // };

  // const deleteCollection = async (db, collectionPath, batchSize) => {
  //   console.log(collectionPath);
  //   const collectionRef = db.collection(collectionPath);
  //   const query = collectionRef.orderBy('__name__').limit(batchSize);

  //   return new Promise((resolve, reject) => {
  //     deleteQueryBatch(db, query, resolve).catch(reject);
  //   });
  // };

  const deleteMonth = async () => {
    // const collections = ['Agenda', 'CashFlow', 'Forecasting', 'ChangeOrders', 'OverUnderBilling'];
    // const promises = [];
    // collections.forEach((collection) => {
    //   console.log(`Deleting collection ${collection}`);
    //   promises.push(deleteCollection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/${collection}`, 500));
    // });
    // Promise.all(promises).then(async () => {
    //   const res = await firestore.collection('MonthEnd').doc(monthEndDate).delete();
    //   console.log('All collections deleted');
    // });
    // path: `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}`
    // functionCall({ jobid: jctdscid, MonthEnd: monthEndDate });
  };

  return (
    <Paper variant='outlined' className='aldg-field'>
      <Grid container>
        <Grid item xs={12}>
          <TextDisplay label='Month Pulled On' value={MonthStartedDate ? dayjs(MonthStartedDate).format('YYYY-MM-DD hh:mm a') : ''} />
          <TextDisplay label='Month Pulled By' value={contractValues?.MonthStartedBy || ''} />
        </Grid>
        <Grid item xs={12} sx={{ paddingBlock: 3 }}>
          <TextDisplay label='Forecast Updated On' value={ForecastLastUpdated ? dayjs(ForecastLastUpdated).format('YYYY-MM-DD hh:mm a') : ''} />
          <TextDisplay label='Forecast Updated By' value={contractValues?.ForecastLastUpdatedBy || ''} />
        </Grid>
        <Grid item xs={12}>
          <TextDisplay label='Cashflow Updated On' value={CashflowLastUpdated ? dayjs(CashflowLastUpdated).format('YYYY-MM-DD hh:mm a') : ''} />
          <TextDisplay label='Cashflow Updated By' value={contractValues?.CashflowLastUpdatedBy || ''} />
        </Grid>
        <Grid item xs={12}>
          <ToolTipIconButton onClick={() => deleteMonth()} icon={faBackward} label={`Delete Month End ${monthEndDate}`} />
        </Grid>
      </Grid>
    </Paper>
  );
};

MonthRollback.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  monthEndDate: PropTypes.string.isRequired
};
MonthRollback.defaultProps = {};

export default MonthRollback;
