/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { faBackward, faDownload, faRotate, faUpload } from '@fortawesome/pro-regular-svg-icons';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { isFunction, isNull } from '@aldridge/aldg-helpers';
import dayjs from 'dayjs';
import { writeBatch, doc, collection } from 'firebase/firestore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Paper } from '@mui/material';
import { UserContext } from '../../../providers/UserProvider';
import existsWithLength from '../../../utils/existsWithLength';
import fileUploadClick from '../../../utils/fileUploadClick';
import { _Defaults, _SecurityLevel } from '../../../_Recoil/atoms';
import { basicFont, formatSubTableHeader, currency$ } from '../../../_helpers/ExcelJSHelpers';
import TooltipIconButton from '../../UIComponents/TooltipIconButton';
import PromptUpload from '../../UIComponents/PromptUpload';
import getMostRecentMonth from '../../../utils/getMostRecentMonth';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { firestore } from '../../../firebase';
import { EditorUp } from '../UserAssignment/checkSecurity';
import uploadAttachment from '../Attachments/uploadAttachment';
import pullLastCashFlow from './pullLastCashFlow';
import { StatusContext } from '../../../providers/StatusProvider';

const DownloadUploadCashFlow = (props) => {
  const { months, monthEndDate, jobName, projectedTotals, jctdscid, getRows, canEdit, refreshData, lastUpdated } = props;
  const Defaults = useRecoilValue(_Defaults);
  const SecurityLevel = useRecoilValue(_SecurityLevel);
  const [attachPrompted, setAttachedPrompted] = useState(false);
  const user = useContext(UserContext);
  const { loadAttachments } = useContext(StatusContext);

  const editableColumns = ['Labor', 'Material', 'Subcontracts', 'Equipment', 'Other', 'ProjectedBillings', 'DelayedReceipts'];
  const columnProperties = [
    { key: 'Month', width: 13, style: basicFont },
    { key: 'Labor', width: 15, style: basicFont, format: currency$ },
    { key: 'Material', width: 15, style: basicFont, format: currency$ },
    { key: 'Subcontracts', width: 15, style: basicFont, format: currency$ },
    { key: 'Equipment', width: 15, style: basicFont, format: currency$ },
    { key: 'Other', width: 15, style: basicFont, format: currency$ },
    {
      key: 'CumulativeJobTotal',
      width: 16,
      style: basicFont,
      format: currency$,
      formula: (rowNum) => `SUM(B${rowNum}:F${rowNum})${rowNum > 2 ? `+G${rowNum - 1}` : ''}`
    },
    { key: 'PercentComplete', width: 6, style: basicFont, format: '0%', formula: (rowNum) => `IFERROR(G${rowNum}/${projectedTotals.totalPT},"%")` },
    { key: 'ProjectedBillings', width: 16, style: basicFont, format: currency$ },
    {
      key: 'RetentionHeld',
      width: 15,
      style: basicFont,
      format: currency$,
      formula: (rowNum) => `I${rowNum}*${Defaults.RetentionPercent}`
    },
    {
      key: 'ProjectedReceipts',
      width: 16,
      style: basicFont,
      format: currency$,
      formula: (rowNum) =>
        rowNum > Math.ceil(Defaults.DSOModel) + 1 ? `I${rowNum - Math.ceil(Defaults.DSOModel)} - J${rowNum - Math.ceil(Defaults.DSOModel)}` : '0-0'
    },
    { key: 'DelayedReceipts', width: 10, style: basicFont, format: currency$ },
    {
      key: 'ProjectedDisbursements',
      width: 16,
      style: basicFont,
      format: currency$,
      formula: (rowNum) => {
        let L = '0';
        let M = '0';
        let S = '0';
        let E = '0';
        let O = '0';
        if (rowNum > Defaults.LaborEquipOther + 1) {
          L = `B${rowNum - Defaults.LaborEquipOther}`;
          E = `E${rowNum - Defaults.LaborEquipOther}`;
          O = `F${rowNum - Defaults.LaborEquipOther}`;
        }
        if (rowNum > Defaults.Materials + 1) M = `C${rowNum - Defaults.Materials}`;
        if (rowNum > Defaults.Subs + 1) S = `D${rowNum - Defaults.Subs}`;

        return `${L} + ${M} + ${S} + ${E} + ${O}`;
      }
    },
    {
      key: 'CumulativeCashPosition',
      width: 16,
      style: basicFont,
      format: currency$,
      formula: (rowNum) => `+(K${rowNum} - L${rowNum})-M${rowNum}${rowNum > 2 ? `+N${rowNum - 1}` : ''}`
    },
    {
      key: 'MonthCostVBilling',
      width: 16,
      style: basicFont,
      format: currency$,
      formula: (rowNum) => `+I${rowNum}-SUM(B${rowNum}:F${rowNum})`
    }
  ];

  const colorBlue = (worksheet, blues) => {
    blues.forEach((blue) => {
      worksheet.addConditionalFormatting({
        ref: `${blue[0]}:${blue[1]}`,
        rules: [
          {
            type: 'expression',
            formulae: [true],
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: 'DAEEF3' }
              }
            }
          }
        ]
      });
    });
  };
  const colorOrange = (worksheet, oranges) => {
    oranges.forEach((orange) => {
      worksheet.addConditionalFormatting({
        ref: `${orange[0]}:${orange[1]}`,
        rules: [
          {
            type: 'expression',
            formulae: [true],
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: 'FFC000' }
              }
            }
          }
        ]
      });
    });
  };
  const downloadTemplate = () => {
    const workbook = new ExcelJS.Workbook();
    // create worksheet
    const CashFlow = workbook.addWorksheet('Cash Flow Template', {
      pageSetup: { paperSize: 9, orientation: 'landscape' }
    });

    // create columns and header
    CashFlow.columns = columnProperties;
    const formulasForAllCells = ['CumulativeCashPosition', 'CumulativeJobTotal', 'MonthCostVBilling', 'PercentComplete'];
    const blueObj = { columns: [], start: -1, end: -1 };
    const orangeObj = { columns: [], start: -1, end: -1 };

    // add headers
    CashFlow.getRow(1).values = [
      'Month',
      'Labor',
      'Material',
      'Subcontracts',
      'Equipment',
      'Other',
      'Cumulative Job Total',
      '%',
      'Projected Billings',
      'Retention Held',
      'Projected Receipts',
      'Delayed Receipts',
      'Projected Disbursements',
      'Cumulative Cash Position',
      'Month Cost vs. Billing'
    ];
    formatSubTableHeader(CashFlow, 1);

    // add data
    CashFlow.addRows(months);

    // add total row
    CashFlow.addRow({
      Month: 'REMAINING'
    });
    // format data and add formulas
    columnProperties.forEach((col) => {
      CashFlow.getColumn(col.key).eachCell((cell, rowNum) => {
        if (rowNum > 1) {
          cell.numFmt = col.format;
          if (rowNum < months.length + 2) {
            if (!months[rowNum - 2].isActual || formulasForAllCells.includes(col.key)) {
              cell.value = isFunction(col.formula) ? { formula: col.formula(rowNum) } : cell.value;
            } else if (months[rowNum - 2].isActual && col.key === 'CumulativeCashPosition') {
              cell.value = isFunction(col.formula) ? { formula: col.formula(rowNum) } : cell.value;
            }
            if (!cell.value) {
              cell.value = 0;
            }
            // color actuals orange
            if (months[rowNum - 2].isActual) {
              if (orangeObj.start === -1) {
                orangeObj.start = rowNum;
              }
              orangeObj.end = rowNum;
              if (!orangeObj.columns.includes(cell.address.charAt(0))) orangeObj.columns.push(cell.address.charAt(0));
            }
            // color projected blue
            if (!months[rowNum - 2].isActual) {
              if (blueObj.start === -1) {
                blueObj.start = rowNum;
              }
              blueObj.end = rowNum;
              if (!blueObj.columns.includes(cell.address.charAt(0)) && editableColumns.includes(col.key))
                blueObj.columns.push(cell.address.charAt(0));
            }
          }
        }
      });
    });

    colorBlue(
      CashFlow,
      blueObj.columns.map((c) => [`${c}${blueObj.start}`, `${c}${blueObj.end}`])
    );
    colorOrange(
      CashFlow,
      orangeObj.columns.map((c) => [`${c}${orangeObj.start}`, `${c}${orangeObj.end}`])
    );

    // add total row formulas
    CashFlow.getCell(`B${months.length + 2}`).value = { formula: `+SUM(B2:B${months.length + 1})-${projectedTotals.laborPT}` };
    CashFlow.getCell(`C${months.length + 2}`).value = { formula: `+SUM(C2:C${months.length + 1})-${projectedTotals.materialPT}` };
    CashFlow.getCell(`D${months.length + 2}`).value = { formula: `+SUM(D2:D${months.length + 1})-${projectedTotals.subPT}` };
    CashFlow.getCell(`E${months.length + 2}`).value = { formula: `+SUM(E2:E${months.length + 1})-${projectedTotals.equipPT}` };
    CashFlow.getCell(`F${months.length + 2}`).value = { formula: `+SUM(F2:F${months.length + 1})-${projectedTotals.otherPT}` };
    CashFlow.getCell(`G${months.length + 2}`).value = { formula: `G${months.length + 1}` };
    CashFlow.getCell(`I${months.length + 2}`).value = { formula: `+SUM(I2:I${months.length + 1})-${projectedTotals.contractVal}` };
    CashFlow.getCell(`J${months.length + 2}`).value = { formula: `+SUM(J2:J${months.length + 1})` };
    CashFlow.getCell(`K${months.length + 2}`).value = { formula: `+SUM(K2:K${months.length + 1})-${projectedTotals.contractVal}` };
    CashFlow.getCell(`L${months.length + 2}`).value = { formula: `+SUM(L2:L${months.length + 1})` };
    CashFlow.getCell(`M${months.length + 2}`).value = { formula: `+SUM(M2:M${months.length + 1})-${projectedTotals.totalPT}` };
    CashFlow.getCell(`N${months.length + 2}`).value = {
      formula: `N${months.length + 1}`
    };

    // save final workbook
    workbook.xlsx.writeBuffer().then((r) => {
      saveAs(new Blob([r.buffer]), `${monthEndDate}, ${jobName}-CashFlow.xlsx`);
      toast.success(`Your Cash Flow template for Month End Date of ${monthEndDate} successfully downloaded.`);
    });
  };

  const uploadTemplate = (toSave) => {
    const callback = (filedata) => {
      const workbook = new ExcelJS.Workbook();
      workbook.xlsx.load(filedata).then(() => {
        const CashFlow = workbook._worksheets.find((sheet) => existsWithLength(sheet) && sheet.name === 'Cash Flow Template');
        if (existsWithLength(CashFlow)) {
          const cfHeaders = columnProperties.map((cp) => cp.key);
          const cfRange = [['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'], { start: 2, end: CashFlow.lastRow.number }];

          const batch = writeBatch(firestore);
          for (let i = cfRange[1].start; i < cfRange[1].end; i++) {
            const cashFlowObj = {};
            cfRange[0].forEach((letter, idx) => {
              let finalVal = 0;
              const cell = CashFlow.getCell(`${letter}${i}`);
              if (cell.value === null) {
                finalVal = 0;
              } else if (typeof cell.value === 'object') finalVal = cell.value.result;
              else finalVal = cell.value;
              if (existsWithLength(finalVal)) cashFlowObj[cfHeaders[idx]] = finalVal;
            });
            dayjs.extend(customParseFormat);
            dayjs.extend(isSameOrAfter);

            // strip out all but month and year using a regular expression
            const newMonth = cashFlowObj.Month.match(
              /(January|February|March|April|May|June|July|August|September|October|November|December)-[0-9]{2}/
            );

            if (cashFlowObj.Month === 'REMAINING') {
              break;
            }
            if (isNull(newMonth)) {
              toast.error(
                `An incorrectly labeled month (${cashFlowObj.Month})was included in your Excel workbook. Therefore, this upload has failed. Please ensure all months are labeled as "Full Month-2 Digit Year" (i.e. January-23)`
              );
              break;
            }

            cashFlowObj.Month = newMonth[0];

            if (dayjs(cashFlowObj.Month, 'MMMM-YY').endOf('month').isSameOrAfter(dayjs(monthEndDate))) {
              const newDoc = doc(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/CashFlow`), cashFlowObj.Month);
              batch.set(newDoc, cashFlowObj, { merge: true });
            }
          }
          batch
            .commit()
            .then(() => {
              getRows();
              toast.success('Uploaded!');
            })
            .catch((err) => toast.error(`Upload failed. ${err}`));
        } else {
          toast.error(
            'The uploaded workbook does not contain a sheet named "Cash Flow Template". Please ensure you uploaded the correct spreadsheet.'
          );
        }
      });
    };
    const fileCallback = (files) => {
      if (toSave) uploadAttachment(files, jctdscid, true, loadAttachments, () => {}, user, monthEndDate, 'CashFlow');
      setAttachedPrompted(false);
    };

    fileUploadClick('cashflowupload', callback, fileCallback);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
        <TooltipIconButton icon={faDownload} title='Download Cash Flow Template' onClick={downloadTemplate} />
        {canEdit ? (
          EditorUp(SecurityLevel) && attachPrompted ? (
            <div>
              <PromptUpload upload={uploadTemplate} setAttachedPrompted={setAttachedPrompted} />
            </div>
          ) : (
            <TooltipIconButton icon={faUpload} title='Upload Cash Flow Template' onClick={() => setAttachedPrompted(true)} />
          )
        ) : null}
        {EditorUp(SecurityLevel) &&
          canEdit &&
          months
            ?.filter((m) => !m.isActual)
            ?.reduce(
              (acc, m) =>
                NaNtoZero(acc) +
                NaNtoZero(m?.Labor) +
                NaNtoZero(m?.Material) +
                NaNtoZero(m?.Subcontracts) +
                NaNtoZero(m?.Equipment) +
                NaNtoZero(m?.Other),
              0
            ) === 0 && (
            <TooltipIconButton
              icon={faBackward}
              title='Pull Last Month Cash Flow'
              onClick={async () => {
                const lastMonth = await getMostRecentMonth(jctdscid, monthEndDate);
                pullLastCashFlow(jctdscid, monthEndDate, lastMonth, getRows);
              }}
            />
          )}

        <TooltipIconButton tooltipText='Refresh' onClick={refreshData} icon={faRotate} color='gray' title='Refresh Projected Cash Flow Analysis' />
      </div>
      <div>
        {lastUpdated !== '' ? (
          <Paper variant='outlined' style={{ width: '100%', alignSelf: 'center', whiteSpace: 'pre-wrap', fontSize: 18 }}>
            <b>Cashflow was last updated:</b> <i>{dayjs(lastUpdated).format('MMM D, YYYY h:mm A')}</i>
          </Paper>
        ) : null}
      </div>
    </>
  );
};

DownloadUploadCashFlow.propTypes = {
  months: PropTypes.arrayOf(PropTypes.object),
  monthEndDate: PropTypes.string,
  jobName: PropTypes.string,
  projectedTotals: PropTypes.object,
  jctdscid: PropTypes.string,
  getRows: PropTypes.func,
  canEdit: PropTypes.bool,
  refreshData: PropTypes.func,
  lastUpdated: PropTypes.string
};
DownloadUploadCashFlow.defaultProps = {
  months: [],
  monthEndDate: '2010-12-31',
  jobName: '',
  projectedTotals: {
    totalPT: 0,
    laborPT: 0,
    materialPT: 0,
    subPT: 0,
    equipPT: 0,
    otherPT: 0,
    contractVal: 0
  },
  jctdscid: '',
  getRows: () => {},
  canEdit: false,
  refreshData: () => {},
  lastUpdated: ''
};

export default DownloadUploadCashFlow;
