/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Grid, Modal, Typography } from '@mui/material';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { allPartsExistWithLength, existsWithLength, useFunctionCall } from '@aldridge/aldg-helpers';
import { getDoc, doc, setDoc, getDocs, collection } from 'firebase/firestore';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { _Clickstream, _Defaults, _SecurityLevel } from '../../../_Recoil/atoms';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { formatAsPercent, roundedFormatAsCurrency } from '../../UIComponents/format';
import getLastIndex from '../../../utils/getLastIndex';
import { nonMuiThemes } from '../../../theme';
import { StatusContext } from '../../../providers/StatusProvider';
import { config, firestore } from '../../../firebase';
import CostType from './sharedColumns';
import DownloadUploadCashFlow from './DownloadUploadCashFlow';
import { AdminUp, DivisionalUp, EditorUp, SuperAdmin, ViewerUp } from '../UserAssignment/checkSecurity';
import { UserContext } from '../../../providers/UserProvider';

const ProjectedAnalysisPM = (props) => {
  const { totalPT, monthEndDate, Transaction, laborPT, materialPT, subPT, equipPT, otherPT, CurrentContractValue, memorialized } = props;
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState([]);
  const [updatePromises, setUpdatePromises] = useState([]);
  const [notAllZero, setNotAllZero] = useState(false);
  const Defaults = useRecoilValue(_Defaults);
  const Clickstream = useRecoilValue(_Clickstream);
  const Role = useRecoilValue(_SecurityLevel);
  const TotalNetCashWO = CurrentContractValue - totalPT;
  const user = useContext(UserContext);
  const { contractValues } = useContext(StatusContext);
  const { FirebaseTiesOut } = contractValues || {};

  const mounted = useRef(null);

  const { data, loading, error, functionCall } = useFunctionCall(
    'AccountingDetail',
    {
      jctdscid: Transaction.id,
      monthEnd: monthEndDate,
      process: 'C'
    },
    540000
  );
  const [initialLoad, setInitialLoad] = useState(false);

  const inAgenda = Clickstream.inAgenda;
  const canEdit = !loading && !inAgenda && !memorialized && EditorUp(Role);

  const roundValue = (value) => roundedFormatAsCurrency(Math.trunc(value), true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // useEffect(() => {
  //   if (existsWithLength(Transaction.id) && mounted.current) functionCall({ jctdscid: Transaction.id, monthEnd: monthEndDate, process: 'C' });
  // }, [monthEndDate, Transaction.id]);

  const refreshData = () => {
    if (existsWithLength(Transaction.id) && mounted.current) {
      functionCall({ jctdscid: Transaction.id, monthEnd: monthEndDate, process: 'C' });
      setDoc(
        doc(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}`),
        { CashflowLastUpdated: new Date().toJSON(), CashflowLastUpdatedBy: user.email },
        { merge: true }
      );
    }
  };

  const GetValueAtCell = (ref, month, field) => {
    try {
      return ref.current.getCellValue(month, field);
    } catch (err) {
      return 0;
    }
  };
  const LMSEO = ['Labor', 'Material', 'Subcontracts', 'Equipment', 'Other'];

  const createTotalRow = (monthRows) => [
    {
      Month: 'TOTAL',
      id: 'TOTAL',
      Labor: Math.trunc(monthRows.reduce((totalLabor, mr) => totalLabor + NaNtoZero(mr.Labor), 0) - laborPT),
      Material: Math.trunc(monthRows.reduce((totalMaterial, mr) => totalMaterial + NaNtoZero(mr.Material), 0) - materialPT),
      Subcontracts: Math.trunc(monthRows.reduce((totalSubcontracts, mr) => totalSubcontracts + NaNtoZero(mr.Subcontracts), 0) - subPT),
      Equipment: Math.trunc(monthRows.reduce((totalEquipment, mr) => totalEquipment + NaNtoZero(mr.Equipment), 0) - equipPT),
      Other: Math.trunc(monthRows.reduce((totalOther, mr) => totalOther + NaNtoZero(mr.Other), 0) - otherPT),
      CumulativeJobTotal: GetValueAtCell(apiRef, getLastIndex(monthRows).Month, 'CumulativeJobTotal') - Math.trunc(Math.round(totalPT)),
      ProjectedBillings: Math.trunc(
        monthRows.reduce((totalBillings, mr) => totalBillings + NaNtoZero(mr.ProjectedBillings), 0) - CurrentContractValue
      ),
      RetentionHeld: Math.trunc(
        monthRows.reduce(
          (totalRetention, mr) => totalRetention + (mr.isActual ? mr.RetentionHeld : NaNtoZero(mr.ProjectedBillings) * Defaults.RetentionPercent),
          0
        )
      ),
      ProjectedReceipts: Math.trunc(
        monthRows.reduce(
          (totalReceipts, mr) =>
            totalReceipts + ((mr.isActual ? mr.ProjectedReceipts : 0) + (!mr.isActual ? GetValueAtCell(apiRef, mr.Month, 'ProjectedReceipts') : 0)),
          0
        ) - CurrentContractValue
      ),
      DelayedReceipts: Math.trunc(
        monthRows.reduce(
          (totalDeReceipts, mr) =>
            totalDeReceipts +
            (NaNtoZero(mr.DelayedReceipts) === 0 ? GetValueAtCell(apiRef, mr.Month, 'DelayedReceipts') : NaNtoZero(mr.DelayedReceipts)),
          0
        )
      ),
      ProjectedDisbursements: Math.trunc(
        monthRows.reduce(
          (totalDisbursements, mr) =>
            totalDisbursements +
            ((mr.isActual ? mr.ProjectedDisbursements : 0) + (!mr.isActual ? GetValueAtCell(apiRef, mr.Month, 'ProjectedDisbursements') : 0)),
          0
        ) - totalPT
      ),
      RevisedProjectedReceipts: Math.trunc(
        monthRows.reduce((totalProjectedReceipts, mr) => totalProjectedReceipts + NaNtoZero(mr.RevisedProjectedReceipts), 0)
      ),
      CumulativeCashPosition: GetValueAtCell(apiRef, getLastIndex(monthRows).Month, 'CumulativeCashPosition') - TotalNetCashWO
    }
  ];

  useEffect(() => {
    setInitialLoad(true);
    dayjs.extend(isSameOrBefore);
    getDocs(collection(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/CashFlow`)).then((docs) => {
      const dd = docs.docs.map((d) => ({ ...d.data(), id: d.id }));
      dd.sort((a, b) => dayjs(a.id, 'MMMM-YY') - dayjs(b.id, 'MMMM-YY'));
      setRows(dd);
      setInitialLoad(false);
    });
  }, [monthEndDate, Transaction.id]);

  const getRows = () => {
    apiRef.current?.setRows([]);
    if (existsWithLength(data)) {
      const startMonth = dayjs(Transaction.EcmsStartDate).endOf('month');
      const endMonth = dayjs(
        existsWithLength(Transaction.CashFlowCompletionDate) ? Transaction.CashFlowCompletionDate : Transaction.EcmsOriginalCompletionDate
      ).endOf('month');

      const promises = [];
      let currentMonth = startMonth;
      dayjs.extend(isSameOrBefore);

      const t1 = [];
      data.forEach((d, idx) => {
        const currMonthTotal = d.LaborBillings + d.MaterialBillings + d.SubcontractorBillings + d.EquipmentBillings + d.OtherBillings;
        const rec = {
          id: dayjs(d.MonthEndDate).format('MMMM-YY'),
          Month: dayjs(d.MonthEndDate).format('MMMM-YY'),
          ProjectedBillings: d.ProjectBillings,
          ProjectedReceipts: d.ProjectReceipts,
          ProjectedDisbursements: d.ProjectDisbursements,
          Labor: d.LaborBillings,
          Material: d.MaterialBillings,
          Subcontracts: d.SubcontractorBillings,
          Equipment: d.EquipmentBillings,
          Other: d.OtherBillings,
          CurrentMonthTotal: currMonthTotal,
          CumulativeCashPosition: 0,
          MonthCostVBilling: 0,
          PercentComplete: 0,
          RetentionHeld: d.RetentionHeld,
          isActual: true
        };
        rec.CumulativeJobTotal = rec.CurrentMonthTotal + (idx > 0 ? t1[idx - 1].CumulativeJobTotal : 0);
        rec.PercentComplete = rec.CumulativeJobTotal / totalPT;
        rec.CumulativeCashPosition = rec.ProjectedReceipts - rec.ProjectedDisbursements - (idx > 0 ? t1[idx - 1].CumulativeCashPosition : 0);
        rec.MonthCostVBilling = rec.ProjectedBillings - rec.CurrentMonthTotal;
        t1.push(rec);
        setDoc(doc(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/CashFlow/${rec.id}`), rec, { merge: true });
      });

      while (dayjs(currentMonth).isSameOrBefore(dayjs(endMonth), 'month')) {
        const m = dayjs(currentMonth).endOf('month');
        promises.push(getDoc(doc(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/CashFlow`, m.format('MMMM-YY'))));
        currentMonth = m.add(1, 'month');
      }

      Promise.all(promises).then((results) => {
        const tempRows = t1;
        results.forEach((d) => {
          if (d.exists()) {
            const idx = tempRows.findIndex((t) => t.id === d.data().id);
            if (idx > -1) {
              tempRows[idx] = { ...d.data(), ...tempRows[idx] };
            } else {
              tempRows.push({ ...d.data(), id: d.id });
            }
          } else {
            const r = {
              id: d.id,
              Month: d.id,
              isActual: false,
              Labor: 0,
              Material: 0,
              Subcontracts: 0,
              Equipment: 0,
              Other: 0,
              CumulativeJobTotal: 0,
              PercentComplete: 0,
              RetentionHeld: 0,
              ProjectedReceipts: 0,
              ProjectedDisbursements: 0,
              CumulativeCashPosition: 0,
              MonthCostVBilling: 0,
              ProjectedBillings: 0
            };
            tempRows.push(r);
            setDoc(d.ref, r);
          }
        });
        if (mounted.current) setRows(tempRows);
      });
    } else {
      setRows([]);
    }
  };

  useEffect(() => {
    setRows([]);
  }, [monthEndDate, Transaction.id]);

  useEffect(() => {
    getRows();
  }, [data]);

  const gridRendered = () => allPartsExistWithLength(apiRef, 'current', 'getAllRowIds') && existsWithLength(apiRef.current.getAllRowIds());

  const checkIfGridRendered = () => {
    if (!gridRendered()) {
      window.setTimeout(checkIfGridRendered, 200);
    } else {
      const oldTotalRow = rows.find((r) => r.id === 'TOTAL');
      const newRows = rows.filter((r) => r.id !== 'TOTAL');
      const customTotalRow = createTotalRow(newRows);
      if (JSON.stringify(oldTotalRow) !== JSON.stringify(customTotalRow[0])) {
        if (mounted.current) {
          // const tiesOut = LMSEO.concat(['CumulativeJobTotal', 'ProjectedBillings']).every((z) =>
          //   roundedFormatAsCurrency(Math.trunc(NaNtoZero(customTotalRow[0][z], false, 0)))
          // );
          const bounds = [-10, 10];
          const tiesOut = !LMSEO.concat(['CumulativeJobTotal', 'ProjectedBillings'])
            .map((z) => Math.trunc(NaNtoZero(customTotalRow[0][z], false, 0)))
            .map((z) => z < bounds[1] && z > bounds[0])
            .includes(false);
          // console.log(
          //   LMSEO.concat(['CumulativeJobTotal', 'ProjectedBillings'])
          //     .map((z) => Math.trunc(NaNtoZero(customTotalRow[0][z], false, 0)))
          //     .map((z) => z < bounds[1] && z > bounds[0]),
          //   tiesOut
          // );
          setNotAllZero(!tiesOut);
          if (tiesOut !== FirebaseTiesOut) {
            setDoc(doc(firestore, `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}`), { FirebaseTiesOut: tiesOut }, { merge: true });
          }
        }
        if (mounted.current) setRows(newRows.concat(customTotalRow));
      }
    }
  };

  const getCurrentIndex = (params) =>
    rows.findIndex((r) => r.id === params.id || r.id === params.row.Month || r.Month === params.id || r.Month === params.row.Month);

  useEffect(() => {
    if (existsWithLength(rows)) {
      checkIfGridRendered();
    }
  }, [rows]);

  useEffect(() => {
    if (existsWithLength(error)) {
      // eslint-disable-next-line
      console.error(error);
      toast.error('Something went wrong while getting the data. Make sure you are on AEWifi or the Aldridge VPN.');
    }
  }, [error]);

  const getRowId = (params) => params.Month;

  const isTotal = (params) => params.id === 'TOTAL';
  const isActualOrTotal = (params) => params.row.isActual || isTotal(params);

  const calcCumJobTotal = (params) => {
    if (params.id === 'TOTAL') return params.value;
    const currentIndex = getCurrentIndex(params);

    const thisRowVal =
      apiRef.current.getCellValue(params.id || params.row.Month, 'ProjectedReceipts') -
      apiRef.current.getCellValue(params.id || params.row.Month, 'ProjectedDisbursements');
    // + apiRef.current.getCellValue(params.id || params.row.Month, 'DelayedReceipts');

    let prevRow = 0;
    if (currentIndex > 0) {
      const prevRowId = apiRef.current.getRowIdFromRowIndex(currentIndex - 1);
      prevRow = apiRef.current.getCellValue(prevRowId, 'CumulativeCashPosition');
    }
    return thisRowVal + prevRow;
  };

  const statusCols = [
    {
      field: 'Month',
      aggregable: false,
      align: 'center',
      headerAlign: 'center',
      width: 120
    }
  ]
    .concat(
      LMSEO.map((type) =>
        CostType(
          () => 120,
          () => null,
          type
        )
      )
    )
    .concat([
      {
        field: 'CurrentMonthTotal',
        aggregable: false,
        headerName: 'Current Month Total',
        align: 'center',
        headerAlign: 'center',
        width: 120,
        totalRow: true,
        valueGetter: (params) => {
          let thisRowSum = 0;
          LMSEO.forEach((type) => {
            thisRowSum += NaNtoZero(params.row[type]);
          });
          return thisRowSum;
        },
        valueFormatter: (params) => roundValue(params.value)
      },
      {
        field: 'CumulativeJobTotal',
        aggregable: false,
        headerName: 'Cumul. Job Total',
        align: 'center',
        headerAlign: 'center',
        width: 120,
        totalRow: true,
        valueGetter: (params) => {
          if (isTotal(params)) {
            return params.row.CumulativeJobTotal;
          }
          const currentIndex = getCurrentIndex(params);
          let thisRowSum = 0;
          LMSEO.forEach((type) => {
            thisRowSum += NaNtoZero(params.row[type]);
          });
          if (currentIndex <= 0) return thisRowSum;
          const prevRowId = apiRef.current.getRowIdFromRowIndex(currentIndex - 1);
          const prevRowSum = apiRef.current.getCellValue(prevRowId, 'CumulativeJobTotal');
          return thisRowSum + prevRowSum;
        },
        valueFormatter: (params) => roundValue(params.value)
      },
      {
        field: 'PercentComplete',
        aggregable: false,
        headerName: '%',
        align: 'center',
        headerAlign: 'center',
        width: 80,
        valueGetter: (params) => {
          if (isTotal(params)) return '';
          const cumTotal = NaNtoZero(apiRef?.current?.getCellValue(params.id, 'CumulativeJobTotal'));
          return formatAsPercent(cumTotal / totalPT);
        }
      },
      {
        field: 'ProjectedBillings',
        aggregable: false,
        headerName: 'Projected Billings',
        align: 'center',
        headerAlign: 'center',
        width: 120,
        totalRow: true,
        canEdit: true,
        inputType: 'InputNumber',
        cellClassName: (params) => (isActualOrTotal(params) ? '' : 'editable'),
        valueFormatter: (params) => roundValue(params.value),
        onChangeDependents: ['RetentionHeld', 'ProjectedReceipts', 'CumulativeCashPosition', 'MonthCostVBilling']
      },
      {
        field: 'RetentionHeld',
        aggregable: false,
        headerName: 'Retention Held',
        align: 'center',
        headerAlign: 'center',
        width: 120,
        totalRow: true,
        valueGetter: (params) => (isActualOrTotal(params) ? params.row.RetentionHeld : params.row.ProjectedBillings * Defaults.RetentionPercent),
        valueFormatter: (params) => roundValue(params.value)
      },
      {
        field: 'ProjectedReceipts',
        aggregable: false,
        headerName: 'Projected Receipts',
        align: 'center',
        headerAlign: 'center',
        width: 120,
        totalRow: true,
        valueGetter: (params) => {
          if (isActualOrTotal(params)) return NaNtoZero(params.row.ProjectedReceipts) + NaNtoZero(params.row.DelayedReceipts);
          const monthOffset = Math.ceil(Defaults.DSOModel);
          const currentIndex = getCurrentIndex(params);
          const monthOffsetIndex = currentIndex - monthOffset;
          if (monthOffsetIndex < 0) return 0;

          const offsetRowId = apiRef.current.getRowIdFromRowIndex(monthOffsetIndex);
          const offsetBillings = NaNtoZero(apiRef.current.getCellValue(offsetRowId, 'ProjectedBillings'));
          const offsetRetention = NaNtoZero(apiRef.current.getCellValue(offsetRowId, 'RetentionHeld'));
          return offsetBillings - offsetRetention + NaNtoZero(params.row.DelayedReceipts);
        },
        valueFormatter: (params) => roundValue(params.value)
      },
      {
        field: 'DelayedReceipts',
        aggregable: false,
        headerName: 'Delayed Receipts',
        align: 'center',
        headerAlign: 'center',
        width: 120,
        totalRow: true,
        canEdit: true,
        inputType: 'InputNumber',
        cellClassName: (params) => (isActualOrTotal(params) ? '' : 'editable'),
        valueGetter: (params) => params.row.DelayedReceipts || 0,
        valueFormatter: (params) => roundValue(params.value)
      },
      {
        field: 'ProjectedDisbursements',
        aggregable: false,
        headerName: 'Projected Disbursements',
        align: 'center',
        headerAlign: 'center',
        width: 120,
        totalRow: true,
        valueGetter: (params) => {
          if (isActualOrTotal(params)) return NaNtoZero(params.row.ProjectedDisbursements);

          const currentIndex = getCurrentIndex(params);

          let labor = 0;
          let mat = 0;
          let sub = 0;
          let equip = 0;
          let other = 0;

          const LaborEquipOtherRowIndex = currentIndex - Defaults.LaborEquipOther;
          if (LaborEquipOtherRowIndex >= 0) {
            const LaborEquipOtherRowId = apiRef.current.getRowIdFromRowIndex(LaborEquipOtherRowIndex);
            labor = NaNtoZero(apiRef.current.getCellValue(LaborEquipOtherRowId, 'Labor'));
            equip = NaNtoZero(apiRef.current.getCellValue(LaborEquipOtherRowId, 'Equipment'));
            other = NaNtoZero(apiRef.current.getCellValue(LaborEquipOtherRowId, 'Other'));
          }

          const MaterialRowIndex = currentIndex - Defaults.Materials;
          if (MaterialRowIndex >= 0) {
            const MaterialRowId = apiRef.current.getRowIdFromRowIndex(MaterialRowIndex);
            mat = NaNtoZero(apiRef.current.getCellValue(MaterialRowId, 'Material'));
          }

          const SubsRowIndex = currentIndex - Defaults.Subs;
          if (SubsRowIndex >= 0) {
            const SubsRowId = apiRef.current.getRowIdFromRowIndex(SubsRowIndex);
            sub = NaNtoZero(apiRef.current.getCellValue(SubsRowId, 'Subcontracts'));
          }

          return labor + mat + sub + equip + other;
        },
        valueFormatter: (params) => roundValue(params.value)
      },
      {
        field: 'CumulativeCashPosition',
        aggregable: false,
        headerName: 'Cumul. Cash Position',
        align: 'center',
        headerAlign: 'center',
        width: 120,
        valueGetter: (params) => {
          if (isTotal(params)) {
            return '';
          }
          return calcCumJobTotal(params);
        },
        valueFormatter: (params) => roundValue(params.value)
      },
      {
        field: 'MonthCostVBilling',
        aggregable: false,
        headerName: 'Month Cost vs. Billing',
        align: 'center',
        headerAlign: 'center',
        width: 120,
        totalRow: true,
        // TODO
        valueGetter: (params) =>
          isActualOrTotal(params)
            ? params.row.MonthCostVBilling
            : params.row.ProjectedBillings -
              (params.row.Labor + params.row.Material + params.row.Subcontracts + params.row.Equipment + params.row.Other),
        valueFormatter: (params) => (isTotal(params) ? '' : roundValue(params.value))
      }
    ]);

  const isProjected = (params) => !params.row.isActual;
  const getClassName = (params) => {
    if (isTotal(params)) return ' Total';
    if (isProjected(params)) return '';
    return 'actual';
  };

  const moreClasses = [
    { '& .negative': nonMuiThemes.negative },
    { '& .Total': { backgroundColor: '#cdedd2 !important' } },
    {
      '& .editable': {
        bgcolor: '#cddeed',
        fontWeight: '600',
        borderBottom: '1px solid #b3b9bf !important',
        cursor: 'pointer'
      }
    },
    {
      '& .actual': {
        bgcolor: '#FABF8FAA !important',
        borderBottom: '1px solid #b3b9bfBB !important',
        cursor: 'default'
      }
    }
  ];
  useEffect(() => {
    if (updatePromises.length > 0) {
      const uRows = JSON.parse(JSON.stringify(rows)).filter((r) => r.id !== 'TOTAL');
      updatePromises.forEach((u) => {
        const updatedRowIdx = uRows.findIndex((r) => r.id === u.id);
        if (updatedRowIdx > -1) {
          uRows[updatedRowIdx] = u.row;
        }
      });
      if (mounted.current) setRows(uRows);
      checkIfGridRendered();
    }
  }, [updatePromises]);

  const canUserViewNote = (_, rowWriterNote) => {
    if (SuperAdmin(Role)) return true;
    if (AdminUp(Role)) return !SuperAdmin(rowWriterNote);
    if (DivisionalUp(Role)) return !AdminUp(rowWriterNote);
    if (ViewerUp(Role)) return !DivisionalUp(rowWriterNote);
    return false;
  };

  return (
    <Grid container className='aldg-bordered'>
      <Grid item xs={12}>
        <Modal open={loading || initialLoad} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant='h6' color='white'>
            Loading...
          </Typography>
        </Modal>
      </Grid>
      <Grid item xs={12} className='aldg-headerTitle'>
        Projected Cash Flow Analysis
      </Grid>
      <Grid item xs={12} className='aldg-subheaderTitle'>
        PM Projected
      </Grid>
      <Grid item xs={12}>
        <Alert severity='info' style={{ textAlign: 'center' }}>
          If you need more months to properly project the cash flow, please update the <strong>Cash Flow Completion Date</strong> in{' '}
          <strong>Job Information</strong>.
        </Alert>
      </Grid>
      <Grid item xs={12} className='aldg-subheaderTitle'>
        <DownloadUploadCashFlow
          months={rows.filter((r) => r.id !== 'TOTAL')}
          monthEndDate={monthEndDate}
          jobName={Transaction.EcmsJobDescription}
          projectedTotals={{ laborPT, totalPT, materialPT, subPT, equipPT, otherPT, contractVal: CurrentContractValue }}
          jctdscid={Transaction.id}
          getRows={getRows}
          setRows={setRows}
          canEdit={canEdit}
          refreshData={refreshData}
          lastUpdated={contractValues?.CashflowLastUpdated}
        />
      </Grid>
      {notAllZero && (
        <Grid item xs={12}>
          <Alert severity={inAgenda ? 'error' : 'warning'} style={{ textAlign: 'left' }}>
            Your cash flow does not tie out! <br /> Please make sure <b>Labor, Material, Equipment, Subcontracts, Other, Cumulative Job Total,</b> and{' '}
            <b>Projected Billings</b> are all near <b>$0</b> <i>(within ± $10)</i> in the green total row.
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <AldgDataGrid
          containerHeight='750px'
          pageSizeOptions={[10, 20, 30]}
          apiRef={apiRef}
          columns={statusCols}
          // containerHeight='500px'
          editable={canEdit}
          extraClasses={moreClasses}
          FirebaseProps={{
            firebaseConfig: config,
            collection: `ENT-Jobs/${Transaction.id}/MonthEnd/${monthEndDate}/CashFlow`
          }}
          getRowClassName={getClassName}
          globalCellClassName={(params) => {
            if (NaNtoZero(params.value) < -1) return 'negative';
            return '';
          }}
          getRowId={getRowId}
          loading={loading}
          noteSettings={{
            allowNotes: true,
            canUserViewNote,
            getUserRole: () => Role,
            canUserAddNote: EditorUp(Role),
            noteCollection: `ENT-Jobs/${Transaction.id}/AldgDataGridNotes`,
            dataGridName: `Cash Flow Table`,
            meta: {
              MonthEndDate: monthEndDate,
              Page: 'Cash Flow'
            }
          }}
          pinnedColumns={{ left: ['Month'] }}
          pinnedRows={{ bottom: rows.filter((r) => r.id === 'TOTAL') }}
          rowCanBeEdited={isProjected}
          rows={rows}
          setRows={setRows}
          ToolbarProps={{ canAdd: false, canDelete: false }}
          user={user}
          updateOverride={updatePromises}
          setUpdateOverride={setUpdatePromises}
        />
      </Grid>
    </Grid>
  );
};

ProjectedAnalysisPM.propTypes = {
  totalPT: PropTypes.number,
  monthEndDate: PropTypes.string,
  Transaction: PropTypes.object.isRequired,
  laborPT: PropTypes.number,
  materialPT: PropTypes.number,
  subPT: PropTypes.number,
  equipPT: PropTypes.number,
  otherPT: PropTypes.number,
  CurrentContractValue: PropTypes.number,
  memorialized: PropTypes.bool
};
ProjectedAnalysisPM.defaultProps = {
  totalPT: 0,
  monthEndDate: '2010-12-31',
  laborPT: 0,
  materialPT: 0,
  subPT: 0,
  equipPT: 0,
  otherPT: 0,
  CurrentContractValue: 0,
  memorialized: false
};

export default ProjectedAnalysisPM;
